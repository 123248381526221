.ui-images {
  height: 100%;

  .ui-image-dropzone {
    height: 100%;

    .dropzone-wrap {
      height: 100%;
      overflow-x: auto;

      .thumbnails {
        padding: 0 15px;
        white-space: nowrap;

        .thumbnail {
          position: relative;
          display: inline-block;
          border-radius: 10px;
          margin: 18px 9px 0;
          background-size: cover;
          background-color: #f8f8f8;
          background-position: center center;

          .file-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .file-remove {
            position: absolute;
            top: 8px;
            right: 8px;
            border: none;
            border-radius: 20px;
            background-color: #AAAAAA;
            color: #FFFFFF;
            font-size: 16px;
            line-height: 19px;
            width: 19px;
            height: 19px;
            padding: 0;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }

    .ui-button {
      margin-bottom: 8px;
    }
  }
}