.talk-room-list {
  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .talk-room-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: #FFFFFF;
    z-index: 10000;
  }

  .chat-list {
    h3 {
      font-size: 18px;
      line-height: 1.38;
      padding: 16px 24px 0;
      font-weight: bold;
    }

    &.chat-list-direct {
      h3 {
        padding-top: 40px;
      }
    }

    ul {
      margin-top: 19px;
    }

    .room-item {
      button {
        cursor: pointer;
        width: 100%;
        background: transparent;
        border: none;
        text-align: left;
        padding: 11px 24px;
        transition: background-color 0.1s ease;

        @media (min-width: 720px) {
          border-radius: 8px;
        }

        &:hover {
          background-color: #ECECEF;
        }

        .room-button {
          display: flex;
          height: 52px;
        }

        .room-members {
          flex: 0 0 52px;
          width: 52px;
          overflow: hidden;

          .user-profile {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin: 1px 1px -2px;
            border-radius: 4px;
            background-size: cover;
            background-position: center;
          }

          &.count1 {
            .user-profile {
              width: 50px;
              height: 50px;
            }
          }

          &.count2 {
            .user-profile:nth-child(2) {
              transform: translateY(25px);
            }
          }

          &.count3 {
            .user-profile:nth-child(1) {
              margin-right: 20px;
              transform: translateX(13px);
            }
          }
        }

        .room-info {
          flex: 1 1 auto;
          width: calc(100% - 72px - 52px - 20px);
          padding: 0 10px;

          h4 {
            font-size: 16px;
            line-height: 1.5;
            margin: 3px 0;

            em {
              color: #999999;
              margin-left: 10px;
            }
          }

          p {
            font-size: 14px;
            line-height: 1.57;
            color: #999999;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-left: 1px;

            .new {
              border-radius: 20px;
              background-color: #FF0909;
              padding: 2px 5px 2px 7px;
              font-size: 12px;
              color: #FFFFFF;
            }
          }
        }

        .room-meta {
          text-align: right;
          flex: 0 0 72px;
          width: 72px;
          padding: 7px 0;

          .time {
            display: block;
            font-size: 11px;
            line-height: 1.5;
            color: #999999;
            padding-right: 2px;
          }

          .unread {
            display: inline-block;
            font-size: 11px;
            line-height: 1.6;
            text-align: center;
            color: #FFFFFF;
            margin-top: 9px;
            padding: 0 5px;
            border-radius: 16px;
            background-color: #3154FF;
          }
        }
      }
    }
  }
}

.new-room-dim {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.new-room-description {
  position: fixed;
  z-index: 1000000;
  bottom: 0;
  left: 50%;
  border-radius: 10px 10px 0 0;
  transform: translateX(-50%);
  background: #FFFFFF;
  width: 100%;
  color: #191919;
  max-width: 720px;
  text-align: center;
  transition: transform 0.2s ease;

  &.select-enter {
    transform: translate(-50%, 100%);
  }

  &.select-enter-active {
    transform: translateX(-50%);
  }

  &.select-exit {
    transform: translateX(-50%);
  }

  &.select-exit-active {
    transform: translate(-50%, 100%);
  }

  .desc-title {
    position: relative;
    z-index: 2;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: -0.5px;
    padding-top: 59px;
  }

  .desc-description {
    position: relative;
    z-index: 2;
    font-size: 14px;
    line-height: 1.43;
    margin: 10px 0;
  }

  .desc-members {
    text-align: center;
    max-width: 400px;
    max-height: calc(100vh - 300px);
    margin: 0 auto;
    overflow: auto;
    padding: 30px 0 130px;

    .user-profile {
      position: relative;
      display: inline-block;
      padding: 12px 24px;

      .profile-image {
        width: 77px;
        height: 77px;
        box-shadow: 3px 4px 7px 0 rgba(2, 21, 111, 0.15);
        border-radius: 77px;
        background-size: cover;
        background-position: center;
      }

      .ui-country {
        position: absolute;
        top: 80px;
        right: 36px;

        .ui-icon i {
          width: 30px;
          height: 18px;
          box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
        }
      }

      .profile-name {
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        font-size: 12px;
        line-height: 1.83;
        color: #000000;
        margin-top: 10px;
      }
    }
  }

  .desc-button {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    width: 100%;
  }
}