.ui-dropdown {
  .context-wrap {
    .context-dim {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999999;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      transition: opacity 0.15s ease;
    }

    .context-layer {
      position: fixed;
      z-index: 1000000;
      bottom: 0;
      left: 50%;
      border-radius: 10px 10px 0 0;
      transform: translateX(-50%);
      background: #FFFFFF;
      width: 100%;
      max-width: 720px;
      max-height: 50vh;
      overflow: auto;
      transition: transform 0.2s ease;
      padding-bottom: calc(env(safe-area-inset-bottom));

      .ui-txt {
        margin: 24px;
      }
    }

    &.context-transition {
      .context-dim {
        opacity: 0;
      }

      .context-layer {
        transform: translate(-50%, 100%);
      }
    }
  }
}
