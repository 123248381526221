.ui-image {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .ui-icon {
    cursor: pointer;
  }

  .thumbnail {
    width: 89px;
    height: 89px;
    border-radius: 8px;
    background-size: cover;
    background-position: center center;
  }
}
