.layout-content-container.slide-enter-active {
  .my-setting {
    top: 0;
  }
}

.my-setting {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 56px);
  overflow: auto;
  background: #FFFFFF;

  .my-menu {
    margin-top: 17px;

    li {
      height: 52px;
      font-size: 16px;
      color: #191919;
      line-height: 1.38;

      &.label {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        color: #191919;
        padding: 8px 24px;
        height: auto;
      }

      button {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        font: inherit;
        line-height: inherit;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: background-color 0.15s ease;

        @media screen and (min-width: 721px) {
          border-radius: 8px;
        }

        &:hover {
          background-color: #ECECEF;
        }
      }

      .value-left {
        float: left;
        margin: 14px 24px;
        height: 22px;
      }

      .value-right {
        float: right;
        color: #3154FF;
        margin: 14px 24px;
        height: 22px;

        &.gray {
          color: #999999;
        }

        .ui-check {
          margin-top: -2px;
        }
      }

      &::after {
        content: "";
        display: block;
        float: none;
        clear: both;
      }
    }

    &::after {
      content: "";
      display: block;
      margin: 10px 24px;
      height: 1px;
      background-color: #F6F6F6;
    }
  }

  .setting-check-password {
    padding: 19.3vh 0;
  }

  .setting-userid {
    padding: 19.3vh 0;
  }

  .setting-password {
    padding: 19.3vh 0;
  }

  .setting-leave {
    padding: 3.2vh 0;
    margin: 0 25px;
    line-height: 1.38;
    font-size: 16px;

    h3 {
      font-size: 16px;
      font-weight: bold;
      color: #191919;
    }

    ul {
      margin-top: 8px;

      li {
        position: relative;
        margin: 6px 12px 6px 24px;
        color: #191919;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 10px;
          left: -10px;
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background-color: #191919;
        }
      }
    }
  }

  .setting-language {
    .check-item {
      .check-text {
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }
  }
}