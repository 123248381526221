.talk-room-request {
  .group-matching-button {
    background-color: #3154FF;
    margin: 12px 24px;
    border-radius: 8px;
    height: 48px;

    button {
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      line-height: 1.38;
      letter-spacing: -0.5px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;
      padding: 13px 20px;
      background: transparent;
      border:none;
      
      .ing {
        cursor: default;
      }
    }
  }

  .request-dim {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999997;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .requesting-description {
    position: fixed;
    z-index: 999998;
    bottom: 0;
    left: 50%;
    border-radius: 10px 10px 0 0;
    transform: translateX(-50%);
    background: #FFFFFF;
    width: 100%;
    color: #191919;
    height: 93vh;
    max-width: 720px;
    text-align: center;
    transition: transform 0.2s ease;

    &.select-enter {
      transform: translate(-50%, 100%);
    }

    &.select-enter-active {
      transform: translateX(-50%);
    }

    &.select-exit {
      transform: translateX(-50%);
    }

    &.select-exit-active {
      transform: translate(-50%, 100%);
    }

    .desc-back {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 3;
    }

    .desc-title {
      position: relative;
      z-index: 2;
      font-size: 25px;
      font-weight: bold;
      letter-spacing: -0.5px;
      padding-top: 59px;
    }

    .desc-description {
      position: relative;
      z-index: 2;
      font-size: 14px;
      line-height: 1.43;
      margin: 10px 0;
    }

    .desc-bubble {
      position: relative;
      z-index: 2;
      width: 233px;
      margin: 25px auto;
      padding: 10px;
      border-radius: 16px;
      box-shadow: 0 1.5px 5px 0 rgba(0, 13, 77, 0.24);
      background-color: #FFFFFF;
      color: #555555;
      font-size: 14px;
      line-height: 1.6;
    }

    .desc-image {
      position: absolute;
      z-index: 1;
      top: calc(55% + 32px);
      left: 50%;
      width: 275px;
      height: 211px;
      background-image: url(../../assets/images/asset--group-chat.svg);
      background-size: cover;
      transform: translate(-50%, -50%);
    }

    .desc-button {
      position: absolute;
      z-index: 2;
      bottom: calc(10px + env(safe-area-inset-bottom));
      width: 100%;
    }
  }

  .requesting-list {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #FFFFFF;
    z-index: 100000;

    .layout-container {
      height: 100%;
    }

    .list-header {
      position: absolute;
      top: 14px;
      left: 16px;
      padding-top: env(safe-area-inset-top);
    }

    .list-title {
      padding-top: calc(48px + env(safe-area-inset-top));
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: -1px;
      white-space: nowrap;
      text-align: center;
      color: #191919;
    }

    .list-description {
      margin: 6px 0;
      letter-spacing: -0.5px;
      text-align: center;
      color: #767676;

      .wysiwyg {
        font-size: 12px;
        line-height: 1.67;
      }
    }

    .list-search {
      margin: 15px 0 -26px 0;
    }

    .list-items {
      margin-top: 13px;
      height: calc(100% - 280px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      overflow: auto;

      .list-item {
        margin: 15px 0;

        .check-item::after {
          display: none;
        }

        &.coming {
          .item-title {
            color: #BBBBBB;

            span.coming {
              display: inline-block;
              font-size: 12px;
              margin-left: 10px;
              background-color: #DADADF;
              color: #FFFFFF;
              line-height: 12px;
              padding: 3px;
              border-radius: 2px;
            }
          }
        }
      }
    }

    .list-button {
      position: absolute;
      z-index: 2;
      bottom: calc(10px + env(safe-area-inset-bottom));
      width: 100%;
    }
  }
}