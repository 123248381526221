.community-board-list {
  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .board-list {
    margin-top: 1px;

    li {

      &:not(.request) {
        button {
          appearance: none;
          background: transparent;
          border: none;
          cursor: pointer;
          line-height: 52px;
          height: 52px;
          width: 100%;
          color: #191919;
          font-size: 16px;
          text-align: left;
          padding: 0 24px;
          box-sizing: border-box;
          transition: background-color 0.1s ease;

          @media screen and (min-width: 721px) {
            border-radius: 8px;
          }

          .bookmark {
            display: inline-block;
            vertical-align: middle;
            width: 0;
            opacity: 0;
            height: 47px;
            line-height: 47px;
            transition: width 0.2s ease,
                        opacity 0.2s ease;

            &.active {
              width: 23px;
              opacity: 1;
            }
          }

          &:hover {
            background-color: #ECECEF;
          }
        }
      }

      &.request {
        text-align: center;
        padding: 36px 0;

        button {
          border-radius: 8px;
          border: solid 1px #191919;
          background-color: #fff;
          font-size: 16px;
          line-height: 1.38;
          text-align: center;
          color: #191919;
          padding: 13px 14px;
          min-width: 195px;
          transition: background-color 0.1s ease;
          cursor: pointer;

          &:hover {
            background-color: #ECECEF;
          }
        }
      }
    }
  }

  .community-request {
    z-index: 100100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: #FFFFFF;
  }
}