.my-profile {
  .profile-background {
    position: relative;
    z-index: 1;
    height: 41.87vh;
    background-size: cover;
    background-position: center center;
    background-color: #767676;

    @media screen and (min-width: 721px) {
      border-radius: 0 0 14px 14px;
    }

    .profile-background-wrap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .country {
      position: relative;
      top: env(safe-area-inset-top);
      z-index: 2;
      float: left;
      padding: 16px 24px;
    }

    .buttons {
      position: fixed;
      top: env(safe-area-inset-top);
      z-index: 2;
      right: 0;
      padding: 15px 16px;

      &.left {
        left: 0;
        right: auto;
      }

      .ui-icon {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        padding: 3px;
      }

      button {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0 7px 0 0;
        color: #FFFFFF;
        vertical-align: middle;
        cursor: pointer;
      }
    }

    .ui-image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;

      .ui-image-dropzone {
        width: 100%;
        height: 100%;

        & > div {
          width: 100%;
          height: 100%;
        }
      }

      .thumbnail {
        border-radius: 0;

        @media screen and (min-width: 721px) {
          border-radius: 0 0 14px 14px;
        }
      }

      .background-icon {
        position: absolute;
        bottom: 18px;
        right: 18px;
        z-index: 2;
        border-radius: 50%;
        padding: 8px;
        box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.08);
        background-color: #FFFFFF;
      }
    }
  }

  .profile-content {
    position: relative;
    z-index: 2;
    min-height: 58.13vh;
    background-color: #FFFFFF;

    @media (min-width: 720px) {
      border-radius: 8px 8px 0 0;
    }

    .profile-information {
      height: 202px;
      background-color: #FFFFFF;

      @media (min-width: 720px) {
        border-radius: 8px 8px 0 0;
      }

      .profile-image {
        position: relative;
        top: -44.5px;
        width: 89px;
        height: 89px;
        border-radius: 8px;
        box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.08);
        margin: 0 auto -44.5px;
        background-size: cover;
        background-position: center center;
      }

      .profile-nickname {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        text-align: center;
        color: #000000;
        margin: 8px 0;
      }

      .profile-action {
        text-align: center;
        margin-top: 16px;

        button {
          display: inline-block;
          vertical-align: middle;
          border: none;
          background: transparent;
          height: 88px;
          width: 92px;
          white-space: nowrap;
          padding: 16px 0;
          text-align: center;
          font-size: 14px;
          line-height: 1.43;
          color: #191919;
          border-radius: 4px;
          transition: background-color 0.1s ease;
          cursor: pointer;

          &:hover {
            background: #ECECEF;
          }

          .ui-icon {
            display: block;
            margin-bottom: 5px;
          }
        }

        .gap {
          display: inline-block;
          vertical-align: middle;
          margin: 8px 0;
          height: 54px;
          width: 1px;
          background-color: #dcdce0;
        }
      }
    }

    .profile-description {
      min-height: calc(58.13vh - 208px);
      background-color: #f6f6fa;
      overflow: auto;
      padding: 0 0 6px;
      transition: background-color 0.15s ease;

      @media (min-width: 720px) {
        background-color: transparent;
      }

      &::before {
        content: "";
        display: block;
        height: 1px;
        margin: 0 18px;
        background-color: transparent;
        transition: background-color 0.15s ease;

        @media (min-width: 720px) {
          background-color: #DADADF;
        }
      }

      .label {
        margin: 18px 24px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        color: #191919;

        &:first-child {
          padding-top: 5px;
        }
      }

      .content {
        margin: 18px 24px;
        font-size: 16px;
        line-height: 1.38;
        color: #191919;

        &.empty {
          color: #999999;
        }

        &.blocked {
          color: #999999;
          text-align: center;
        }

        .tag {
          float: left;
          white-space: nowrap;
          color: #3154FF;
          margin-right: 5px;
        }
      }
    }

    .profile-editmode {
      background-color: #FFFFFF;
      padding-bottom: 224px;

      @media (min-width: 720px) {
        border-radius: 8px 8px 0 0;
      }

      .profile-image {
        text-align: center;

        .ui-image {
          position: relative;
          top: -44.5px;
          width: 89px;
          height: 89px;
          border-radius: 8px;
          box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.08);
          margin: 0 auto -44.5px;
          background-size: cover;
          background-position: center center;

          .profile-icon {
            position: absolute;
            bottom: -14px;
            right: -13px;
            border-radius: 50%;
            padding: 8px;
            box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.08);
            background-color: #ffffff;
          }
        }
      }

      .profile-information {
        padding-top: 22px;

        .ui-input + .ui-input {
          margin-top: -12px;
        }
      }
    }
  }
}