.tabbar {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;

  .tabbar-button {
    display: inline-block;
    vertical-align: middle;

    .ui-icon {
      padding: 13px;

      .icon-badge {
        top: 8px;
        left: 27px;
      }

      i.active-icon {
        top: 13px;
        left: 13px;
      }
    }
  }
}