.ui-context {
  .context-wrap {
    .context-dim {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999999;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      transition: opacity 0.15s ease;
    }

    .context-layer {
      position: fixed;
      z-index: 1000000;
      bottom: 0;
      left: 50%;
      border-radius: 10px 10px 0 0;
      transform: translateX(-50%);
      background: #FFFFFF;
      width: 100%;
      max-width: 720px;
      padding-bottom: calc(env(safe-area-inset-bottom));
      transition: transform 0.2s ease;

      .select-search {
        padding-top: 6px;

        .ui-input {
          margin: 12px 24px;
          padding: 0;
        }
      }

      .context-items {
        overflow-y: auto;

        .context-title {
          padding: 0 24px;
          height: 52px;
          line-height: 52px;
          font-weight: bold;
        }

        .context-item {
          .context-item-icon {
            border-radius: 6px;
            background-color: #F6F6FA;
            padding: 5px 6px;
            margin-right: 12px;

            .ui-icon {
              .icon--flag {
                border-radius: 2px;
              }
            }
          }

          button {
            appearance: none;
            border: none;
            background-color: #FFFFFF;
            cursor: pointer;
            width: 100%;
            text-align: left;
            padding: 13px 24px;
            height: 52px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            transition: background-color 0.15s ease;
          }

          &:first-child {
            button {
              border-radius: 10px 10px 0 0;
            }
          }

          &:hover,
          &.selected {
            button {
              background-color: #ECECEF;
            }
          }
        }
      }
    }

    &.context-transition {
      .context-dim {
        opacity: 0;
      }

      .context-layer {
        transform: translate(-50%, 100%);
      }
    }
  }
}
