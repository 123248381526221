.ui-check {
  display: inline-block;

  .check-element {
    & + .style-box {
      position: relative;
      display: inline-block;

      .check-icon-wrap {
        transition: opacity 0.1s ease;

        .ui-icon {
          cursor: pointer;
        }

        &.checked {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &:not(:checked) + .style-box {
      .checked {
        opacity: 0;
      }
    }

    & + .style-switch {
      position: relative;
      cursor: pointer;
      display: inline-block;
      width: 50px;
      height: 28px;

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 30px;
        height: 15px;
        top: 50%;
        left: 50%;
        border-radius: 15px;
        background-color: #DCDCE0;
        transform: translate(-50%, -50%);
        transition: background-color 0.15s ease;
      }

      .switch-bullet {
        position: absolute;
        top: 3px;
        left: 0;
        display: block;
        width: 22px;
        height: 22px;
        box-shadow: 2px 3px 9px 0 rgba(25, 25, 25, 0.35);
        background-color: #FFFFFF;
        border-radius: 50%;
        transition: left 0.15s ease,
                    background-color 0.15s ease;
      }
    }

    &:checked + .style-switch {
      &::before {
        background-color: #E0E5FE;
      }

      .switch-bullet {
        left: calc(100% - 22px);
        background-color: #3054F8;
      }
    }
  }
}
