.alert-wrap {
  position: relative;
  z-index: 999999;
  opacity: 1;
  transition: opacity 0.15s ease;

  &.alert-transition {
    opacity: 0;
  }
}

.alert-dim {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.alert-layer {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999999;
  width: 327px;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 8px;

  .alert-content {
    padding: 21px 19px 23px;
    font-size: 16px;
    line-height: 1.38;
    text-align: center;
    color: #191919;
  }

  .alert-button {
    background: transparent;
    border: none;
    line-height: 1.38;
    font-size: 16px;
    color: #191919;
    text-align: center;
    padding: 14px 0;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.15s ease;

    &:focus {
      outline: 0;
      opacity: 0.5;
    }
  }
}