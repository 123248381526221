.place-map {
  position: relative;
  top: calc(0px - env(safe-area-inset-top));

  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .map-search {
    position: fixed;
    top: calc(env(safe-area-inset-top) + 16px);
    left: 0;
    width: 100%;
    z-index: 10;

    .place-type {
      margin-top: -13px;
      padding: 0 20px;
      white-space: nowrap;
      overflow-x: auto;

      .ui-button {
        button {
          box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .map-container {
    position: relative;
    width: 100vw;
    height: calc(100vh - 56px - env(safe-area-inset-bottom));
    z-index: 3;
    background-color: #F6F6FA;

    .current {
      display: block;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 14px;
      background: #2AB059;
      box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 7px rgba(42, 176, 89, 0.4);
      border-radius: 100px;

      &.active {
        animation-duration: 1s;
        animation-name: current-location-active;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }

      @keyframes current-location-active {
        from {
          box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px rgba(42, 176, 89, 0.4);
        }

        to {
          box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 7px rgba(42, 176, 89, 0.4);
        }
      }
    }

    a[href^="https://maps.google.com/maps?"],
    .gmnoprint {
      position: relative;
      transition: top 0.1s ease;
    }

    &.map-recommend {
      a[href^="https://maps.google.com/maps?"],
      .gmnoprint {
        position: relative;
        top: -48px;
      }
    }

    &.map-loading {
      a[href^="https://maps.google.com/maps?"],
      .gmnoprint {
        position: relative;
        top: -64px;
      }
    }

    &.map-no-result {
      a[href^="https://maps.google.com/maps?"],
      .gmnoprint {
        position: relative;
        top: -84px;
      }
    }

    .pin {
      z-index: 8;
      position: relative;
      display: block;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border: 7px solid #3154FF;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
      border-radius: 100px;

      &.level-3 {
        border-color: #0000FF;

        &.active {
          border-color: #0000FF;

          & > svg path {
            fill: #0000FF;
          }
        }
      }

      &.level-2 {
        border-color: #5A5AFF;

        &.active {
          border-color: #5A5AFF;

          & > svg path {
            fill: #5A5AFF;
          }
        }
      }

      &.level-1 {
        border-color: #C5C5FF;

        &.active {
          border-color: #C5C5FF;

          & > svg path {
            fill: #C5C5FF;
          }
        }
      }

      &.active {
        z-index: 10;
        width: 16px;
        height: 16px;
        border: 13px solid #3154FF;
        transform: translate(-50%, calc(-50% - 20px));
        transition: all 0.1s ease;

        & > .points {
          z-index: 20;
          font-size: 20px;
          font-weight: normal;
          top: 9px;
          transition: top 0.1s ease, font-size 0.1s ease;
        }

        & > .ui-icon {
            margin-top: 5px;
            transition: margin-top 0.1s ease;

          i.icon--bookmark {
            width: 32px;
            height: 32px;
            transition: width 0.1s ease, height 0.1s ease;
          }

          i[class^='icon--spot-'] {
            display: none;
          }
        }

        & > .pin-svg {
          transform: scale(1) translate(-50%, calc(-50% + 8px));
          opacity: 1;
        }

        & > svg {
          transition: transform 0.1s ease, opacity 0.1s ease;
        }
      }

      &.cluster {
        width: 24px;
        height: 24px;
        border: none;
        background-color: #3154FF;
        text-align: center;

        &.active {
          width: 40px;
          height: 40px;
        }

        & > .points {
          color: #FFFFFF;
        }
      }

      &.bookmark {
        background-color: #FFFFFF;
        text-align: center;

        &:not(.active) {
          width: 24px;
          height: 24px;
          border: 1px solid #0000FF;
        }

        &.cluster {
          & > .ui-icon {
            margin-top: 0;

            i.icon--bookmark {
              width: 24px;
              height: 24px;
            }
          }

          &.active {
            & > .ui-icon {
              margin-top: 3px;

              i.icon--bookmark {
                width: 38px;
                height: 38px;
              }
            }
          }
        }

        &.active {
          & > .ui-icon {
            display: none;
          }

          & > .points {
            top: 8px;
          }
        }

        & > .ui-icon {
          margin-top: 2px;

          i.icon--bookmark {
            width: 20px;
            height: 20px;
          }
        }

        & > .points {
          top: 4px;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(0) translate(-50%, -50%);
        transform-origin: 0 0;
        opacity: 0;
      }

      .points {
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
      }

      .ui-icon {
        margin-top: 2px;

        i.icon--bookmark {
          width: 20px;
          height: 20px;
        }

        i[class^='icon--spot-'] {
          margin: -9px 0 0 -7px;
          cursor: pointer;
        }
      }
    }

    .point-list {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      white-space: nowrap;
      background-color: #FFFFFF;
      max-width: 30vw;
      min-width: 150px;
      max-height: 180px;
      overflow-y: scroll;
      border-radius: 6px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28);
      text-align: left;
      padding: 5px 0 4px 0;
      z-index: 15;

      &.pop-bottom-enter {
        bottom: 20px;
      }

      &.pop-bottom-enter-active {
        bottom: 50px;
        transition: opacity 0.15s ease, bottom 0.15s ease;
      }

      &.pop-bottom-exit {
        bottom: 50px;
      }

      &.pop-bottom-exit-active {
        bottom: 20px;
        transition: opacity 0.15s ease, bottom 0.15s ease;
      }

      .point {
        position: relative;
        display: block;
        margin: -3px 0;
        padding: 3px 0;
        transition: background-color 0.15s ease;

        &.active {
          background-color: rgba(0, 0, 0, 0.08)
        }

        &:first-child {
          border-radius: 6px 6px 0 0;
        }

        &:last-child {
          border-radius: 0 0 6px 6px;
        }

        .pin {
          position: absolute;
          top: 50%;
          left: 20px;
        }

        .point-name {
          font-size: 14px;
          display: block;
          padding: 8px 10px 8px 38px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .map-data {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 20;

    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.4);
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &.listing {
      &::before {
        opacity: 1;
      }

      .map-control {
        opacity: 0;
      }

      .map-list {
        .list-handle {
          span {
            height: 0;
            opacity: 0;
          }
        }
      }
    }

    .map-control {
      text-align: right;
      padding: 10px;
      transition: opacity 0.3s ease;
    }

    .map-place-item {
      height: 0;
      padding: 0 20px;
      overflow: hidden;
      transition: height 0.3s cubic-bezier(0.65, 0, 0.35, 1),
                  padding 0.3s cubic-bezier(0.65, 0, 0.35, 1);

      &.active {
        height: 120px;
        padding: 6px 20px 20px;
      }

      .place-wrap {
        height: 120px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        display: flex;
        cursor: pointer;

        .thumbnail {
          width: 120px;
          border-radius: 6px 0 0 6px;
          background-size: cover;
          background-position: center center;
        }

        .info {
          width: calc(100% - 120px);
          box-sizing: border-box;
          padding: 12px 16px;
          color: #191919;

          &.no-thumb {
            width: 100%;
          }

          .title {
            position: relative;
            padding-right: 25px;

            h4 {
              font-weight: bold;
              font-size: 16px;
              line-height: 22px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .bookmark {
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;

              .ui-icon {
                i {
                  width: 22px;
                  height: 22px;
                }
              }
            }
          }

          .meta {
            font-size: 14px;
            line-height: 20px;
            margin: 7px 0 3px -13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .ui-icon {
              margin: 0 2px 0 13px;

              i {
                width: 20px;
                height: 20px;
              }
            }

            .item {
              span {
                font-size: 12px;
                color: #999999;
              }
            }
          }

          .description {
            color: #999999;
            font-size: 14px;
            line-height: 20px;
            height: 40px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .map-list {
      background: #F6F6FA;
      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.16);
      border-radius: 6px 6px 0 0;
      min-height: 28px;
      max-height: calc(100vh - 196px);
      height: 1px;
      overflow: hidden;

      &.animating {
        transition: height 0.3s cubic-bezier(0.65, 0, 0.35, 1);
      }

      &.recommend {
        min-height: 48px;
      }

      &.loading {
        min-height: 64px;
      }

      &.no-result {
        min-height: 84px;
      }

      .list-handle {
        padding: 8px 0 16px;
        cursor: ns-resize;
        font-size: 14px;
        line-height: 20px;
        color: #767676;
        text-align: center;
        font-weight: bold;

        &::before {
          content: "";
          display: block;
          width: 40px;
          height: 4px;
          background-color: #999999;
          border-radius: 2px;
          margin: 0 auto 7px;
        }

        span {
          display: block;
          height: 20px;
          transition: height 0.3s cubic-bezier(0.65, 0, 0.35, 1),
                      opacity 0.3s ease;
        }
      }

      .list-search {
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #767676;
        padding: 19px 0;

        .ui-icon {
          margin-top: -2px;
          margin-left: 5px;
          animation: rotation 1s infinite linear;

          @keyframes rotation {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(359deg);
            }
          }
        }
      }

      .list-list {
        height: calc(100% - 35px);
        overflow-y: auto;

        .place-item {
          cursor: pointer;
          padding: 0 20px;
          margin-bottom: 50px;

          .thumbnail {
            height: 196px;
            border-radius: 6px;
            background-size: cover;
            background-position: center center;
          }

          .info {
            color: #191919;

            .ui-icon {
              i {
                width: 25px;
                height: 25px;
              }
            }

            .title {
              padding: 10px 30px 4px 0;
              position: relative;

              h4 {
                font-size: 18px;
                line-height: 24px;
                font-weight: bold;
              }

              .bookmark {
                cursor: pointer;
                position: absolute;
                top: 11px;
                right: 0;

                .ui-icon {
                  i {
                    width: 22px;
                    height: 22px;
                  }
                }
              }
            }

            .meta {
              font-size: 16px;
              line-height: 22px;

              .ui-icon {
                margin-right: 3px;
                margin-left: -3px;
              }

              .item {
                &.open {
                  float: right;
                  font-size: 14px;

                  .ui-icon {
                    i {
                      width: 21px;
                      height: 21px;
                    }
                  }
                }

                span {
                  font-size: 14px;
                  color: #999999;
                }
              }
            }

            .description {
              position: relative;
              font-size: 14px;
              line-height: 20px;

              &.style-box {
                padding-bottom: 25px;

                .input-label {
                  margin-bottom: 15px;
                }

                .input-wrap {
                  padding: 9px 12px 10px;
                }

                input,
                textarea {
                  width: calc(100% - 2px);
                  margin: -15px -12px;
                  padding: 15px 12px;
                  border: 1px solid #DCDCE0;
                  border-radius: 8px;
                  transition: border-color 0.15s ease;

                  &:focus {
                    border-color: #3154FF;
                  }

                  &:disabled {
                    background: #ECECEF;
                  }
                }

                .input-icon {
                  left: 12px;
                }

                .input-eye {
                  right: 12px;
                }

                .input-eraser {
                  right: 12px;
                }

                &.has-icon {
                  input,
                  textarea {
                    width: calc(100% - 43px);
                    padding-left: 43px;
                  }
                }

                .input-error,
                .input-description {
                  position: static;
                  white-space: normal;
                  margin: 10px 0;
                }

                .input-description {
                  color: #999999;
                  font-size: 14px;
                  line-height: 20px;

                  & + .input-error {
                    margin-top: -10px;
                  }
                }

                &.has-error {
                  input,
                  textarea {
                    border-color: #FF0000;
                  }

                  .input-description {
                    display: block;
                  }
                }
              }
              color: #999999;
              padding-right: 100px;
              min-height: 40px;

              .distance {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                line-height: 28px;
                background: #FFFFFF;
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
                border-radius: 3px;
                padding: 0 10px 0 7px;
                font-size: 14px;
                color: #191919;

                .ui-icon {
                  margin-right: 2px;

                  i {
                    width: 21px;
                    height: 21px;
                    margin-bottom: -3px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .map-place-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: #FFFFFF;
    z-index: 10000;
  }

  .term-confirm {
    font-size: 16px;
    line-height: 22px;
    color: #191919;
    text-align: left;
    margin: -6px 0;

    li {
      margin: 16px 0;
      position: relative;
      padding-left: 30px;

      .ui-icon {
        position: absolute;
        top: 2px;
        left: 0;
      }

      p {
        b {
          font-weight: bold;
          color: #3154FF;
        }
      }

      button {
        margin: 0;
        padding: 0;
        text-align: left;
        color: #999999;
        background: none;
        text-decoration: underline;
        border: none;
        cursor: pointer;
      }
    }
  }

  .free-docent-end {
    h3 {
      color: #999999;
      font-weight: bold;
      font-size: 14px;
    }

    h4 {
      color: #191919;
      line-height: 1.6;
      border-top: 1px solid #DADADF;
      border-bottom: 1px solid #DADADF;
      padding: 12px 0;
      margin: 16px 0 18px;
      font-weight: bold;
    }

    p, li {
      text-align: left;
      color: #555555;
      font-size: 13px;
      line-height: 1.7;

      strong {
        font-weight: bold;
        color: #3154FF;
      }
    }

    li {
      list-style: disc;
      margin-left: 20px;
    }

    .alert-button {
      margin: 0 14px 14px;
      background-color: #3154FF;
      color: #FFFFFF;
      font-size: 16px;
      line-height: 22px;
      border-radius: 8px;
      width: calc(100% - 28px);

      &:focus {
        outline: 0;
        opacity: 0.5;
      }
    }
  }
}
