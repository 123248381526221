.my-board-post-list {
  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .post-read-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px - env(safe-area-inset-top));
    padding-top: calc(env(safe-area-inset-top) + 56px);
    overflow: auto;
    background: #FFFFFF;
    z-index: 10000;
  }

  .search-container {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px - env(safe-area-inset-top));
    padding-top: calc(env(safe-area-inset-top));
    overflow: auto;
    background: #FFFFFF;

    .search {
      overflow: hidden;

      .ui-input {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .board-list {
    padding-bottom: 84px;

    li {
      button {
        background: transparent;
        text-align: left;
        border: none;
        cursor: pointer;
        position: relative;
        width: 100%;
        padding: 0 24px;

        .author {
          padding: 24px 0 12px;
          margin-bottom: -12px;
          display: block;
          font-weight: bold;
          font-size: 12px;
          color: #767676;

          .ui-country {
            margin-right: 10px;
          }
        }

        .content {
          display: block;
          line-height: 1.4;
          padding-top: 24px;

          .title {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #191919;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 4px;
          }

          .summary {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #767676;
            font-size: 14px;
            margin-bottom: 18px;
          }

          &.thumb {
            padding-right: 90px;

            .summary {
              white-space: normal;
              height: 42px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }

        .thumbnail {
          position: absolute;
          top: 60px;
          right: 25px;
          width: 70px;
          height: 70px;
          border-radius: 8px;
          background-size: cover;
          background-position: center center;

          &.up {
            top: 34px;
          }
        }

        .meta {
          display: block;
          color: #999999;
          font-size: 12px;
          padding-bottom: 24px;

          .count {
            .comment {
              margin-left: 10px;
            }

            .ui-icon {
              margin-right: 1px;
            }
          }

          .date {
            float: right;

            .gap {
              display: inline-block;
              vertical-align: middle;
              height: 10px;
              width: 1px;
              background: #999999;
              margin: -3px 6px 0;
            }
          }
        }

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #F6F6FA;
        }
      }

      &.no-data {
        text-align: center;
        font-size: 16px;
        color: #999999;
        margin-top: 28px;
      }
    }
  }
}