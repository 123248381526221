.nearcle-home {
  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .banner-carousel {
    .carousel-container {
      position: relative;
      width: 100%;
      height: 58.7vw;
      max-height: 423px;
      overflow: hidden;

      @media screen and (min-width: 721px) {
        margin-top: 16px;
        border-radius: 8px;
      }

      ul {
        position: absolute;
        top: 0;
        left: 0;
        white-space: nowrap;
        width: inherit;
        height: inherit;
        max-height: inherit;
        transition: left 0.5s ease;

        li {
          width: 100%;
          height: 100%;
          display: inline-block;
          vertical-align: top;
          margin: 0 6px;

          a {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
            border: none;
            text-align: left;
            background-size: cover;
            background-position: center center;
            color: #ffffff;

            .title {
              position: absolute;
              top: 10%;
              left: 6.5%;
              font-weight: bold;

              .wysiwyg {
                font-size: 5vw;
                line-height: 1.3;

                @media screen and (min-width: 721px) {
                  font-size: 36px;
                }
              }
            }

            .content {
              position: absolute;
              bottom: 10%;
              left: 6.5%;

              .wysiwyg {
                font-size: 3.5vw;
                line-height: 1.5;

                @media screen and (min-width: 721px) {
                  font-size: 25px;
                }
              }
            }
          }
        }
      }
    }

    .carousel-navigation {
      text-align: center;
      padding: 4px 0 12px;

      .bullet {
        border: none;
        padding: 0;
        margin: 0 1px 0 2px;
        border-radius: 10px;
        width: 6px;
        height: 6px;
        background-color: #DCDCE0;
        cursor: pointer;
        transition: background-color 0.15s ease;

        &.active {
          background-color: #3154FF;
        }
      }
    }
  }

  .talk-list {
    border-top: 8px solid #F6F6FA;
    padding-bottom: 30px;

    h4 {
      padding: 40px 24px 12px 24px;
      font-size: 18px;
      font-weight: bold;
    }

    .talk-matching-button {
      background-color: #3154FF;
      margin: 12px 24px;
      border-radius: 8px;
      height: 48px;

      button {
        width: 100%;
        border-radius: 8px;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: -0.5px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
        padding: 13px 20px;
        background: transparent;
        border: none;

        .ing {
          cursor: default;
        }
      }
    }
  }

  .community-list {
    border-top: 8px solid #F6F6FA;
    padding-bottom: 50px;

    h4 {
      padding: 40px 24px 12px 24px;
      font-size: 18px;
      font-weight: bold;
    }

    ul {
      li {
        button {
          padding: 11px 24px;
          width: 100%;
          background: transparent;
          border: none;
          cursor: pointer;
          font-size: 14px;
          color: #767676;
          text-align: left;

          .board {
            display: inline-block;
            width: 92px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #191919;
            font-weight: bold;
            vertical-align: middle;
          }

          .post {
            display: inline-block;
            width: calc(100% - 92px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
          }
        }
      }
    }

    .board-list {
      padding-bottom: 0;
    }

    .pick-list {
      padding: 6px 20px 0 20px;

      li {
        display: inline-block;
        vertical-align: top;
        padding: 4px;
        width: calc(50vw - 28px);
        max-width: 324px;
        height: calc(50vw - 28px);
        max-height: 324px;
        position: relative;


        button {
          background: #ECECEF;
          text-align: left;
          border: none;
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 8px;

          .thumbnail {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background-size: cover;
            background-position: center center;
          }

          .meta {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            border-radius: 0 0 8px 8px;
            background: linear-gradient(0, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
            font-size: 14px;

            .board {
              display: block;
              color: #FFFFFF;
              padding: 10px 10px 0 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .title {
              display: block;
              color: #FFFFFF;
              padding: 6px 10px 10px 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .reported {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: calc(100% - 20px);
            font-size: 14px;
            color: #AAAAAA;
            padding: 30px 10px 10px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .post-read-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px - env(safe-area-inset-top));
    padding-top: calc(env(safe-area-inset-top) + 56px);
    overflow: auto;
    background: #FFFFFF;
    z-index: 10000;
  }

  .search-container {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px - env(safe-area-inset-top));
    padding-top: calc(env(safe-area-inset-top));
    overflow: auto;
    background: #FFFFFF;

    .search {
      overflow: hidden;

      .ui-input {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .board-list {
    padding-bottom: 84px;

    li {
      button {
        background: transparent;
        text-align: left;
        border: none;
        cursor: pointer;
        position: relative;
        width: 100%;
        padding: 0 24px;

        .author {
          padding: 24px 0 0;
          margin-bottom: -12px;
          display: block;
          font-weight: bold;
          font-size: 12px;
          color: #767676;

          &.blocked {
            opacity: 0.3;
          }

          .ui-country {
            margin-right: 10px;
          }
        }

        .content {
          display: block;
          line-height: 1.4;
          padding-top: 24px;

          .title {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #191919;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 4px;
          }

          .summary {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #767676;
            font-size: 14px;
            margin-bottom: 18px;
          }

          &.reported {
            .summary {
              color: #BBBBBB;
              padding-bottom: 26px;
            }
          }

          &.thumb {
            padding-right: 90px;

            .summary {
              white-space: normal;
              height: 42px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }

        .thumbnail {
          position: absolute;
          top: 60px;
          right: 25px;
          width: 70px;
          height: 70px;
          border-radius: 8px;
          background-size: cover;
          background-position: center center;
        }

        .meta {
          display: block;
          color: #999999;
          font-size: 12px;
          padding-bottom: 24px;

          .count {
            .comment {
              margin-left: 10px;
            }

            .ui-icon {
              margin-right: 1px;
            }
          }

          .date {
            float: right;

            .gap {
              display: inline-block;
              vertical-align: middle;
              height: 10px;
              width: 1px;
              background: #999999;
              margin: -3px 6px 0;
            }
          }
        }

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #F6F6FA;
        }
      }
    }
  }
}