.my-notification {
  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .notification-list {
    padding:18px 0;

    li {
      padding: 6px 24px;

      &.notice {
        text-align: center;
        font-size: 12px;
        color: #AAAAAA;
      }
    }

    button {
      background: #F6F6FA;
      border-radius: 8px;
      border: none;
      width: 100%;
      padding: 12px 12px 6px;
      text-align: left;
      cursor: pointer;

      &.disable {
        background: #F8F8F8;

        * {
          opacity: 0.65;
        }
      }

      .icon {
        float: left;
        width: 36px;
      }

      .text {
        float: left;
        width: calc(100% - 36px);
        line-height: 1.6;
        color: #191919;
        font-size: 14px;

        .title {
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: bold;

          em {
            font-weight: normal;
          }
        }

        .content {
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          em {
            font-weight: bold;
          }
        }

        .date {
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }
}