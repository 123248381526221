.check-select {
  position: relative;
  display: block;
  width: calc(100% - 48px);
  padding: 10px 0 24px;
  margin: 4px 24px;

  .check-select-label {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    line-height: 22px;
    color: #191919;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }

  .check-select-items {
    margin: 0 -8px 0 -9px;

    .check-select-item {
      display: inline-block;
      margin: 0 8px 0 9px;
      box-sizing: border-box;

      .check-item {
        margin: 0;
      }
    }
  }
}