.board-post-comment {
  .replyiing {
    position: relative;
    font-size: 12px;
    padding: 4px 32px 6px 2px;
    white-space: nowrap;
    text-overflow: ellipsis;

    em {
      font-weight: bold;
      margin-right: 10px;
    }

    .ui-icon {
      position: absolute;
      top: -2px;
      right: 0;
    }
  }

  .content {
    position: relative;
    transition: padding-left 0.2s ease;

    .reply-icon {
      position: absolute;
      z-index: 1;
      top: 6px;
      left: -2px;
    }

    textarea {
      position: relative;
      z-index: 2;
      width: calc(100% - 64px);
      padding: 11px 52px 11px 12px;
      border-radius: 8px;
      background-color: #fff;
      font-size: 14px;
      line-height: 1.43;
      text-align: left;
      color: #191919;
      resize: none;

      &::placeholder {
        color: #999999;
      }
    }

    &.replyiing {
      padding-left: 32px;
    }
  }

  .button {
    position: absolute;
    z-index: 3;
    bottom: 6px;
    right: 12px;
  }
}