.ui-country {
  padding: 5px 6px;
  border-radius: 4px;
  background-color: #f6f6fa;
  font-size: 10px;
  line-height: 1.6;
  color: #999999;
  font-weight: bold;
  display: inline-block;
  margin-top: -2px;
  margin-bottom: 2px;

  &.only-flag {
    padding: 0;
    background-color: transparent;
    margin: 0;
  }

  .ui-icon {
    margin-top: 1px;
    margin-right: 2px;
    margin-bottom: -2px;

    i {
      border-radius: 2px;
    }
  }
}