.email-verification {
  padding-bottom: 101px;

  .step-email-1 {
    padding: 19.3vh 0;
  }

  .step-email-2 {
    padding: 3.2vh 0;

    .description {
      margin: 0 25px;
      min-height: 16.1vh;
      line-height: 1.38;
      font-size: 16px;
    }

    .resend {
      color: #3154ff;
      background: transparent;
      border: none;
      padding: 10px 0;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
