.service-center {
  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .term-content {
    margin: 12px 24px;
  }

  .search {
    margin: -1px 0 -18px 0;
  }

  .service-content {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px);
    overflow: auto;
    background: #FFFFFF;

    .content-title {
      font-weight: bold;
      margin: 24px 24px 0;
      line-height: 1.5;
      font-size: 18px;
      color: #191919;
    }

    .content-content {
      margin: 12px 24px;
      color: #191919;
    }
  }

  .service-article {
    li {
      font-size: 16px;
      color: #191919;
      line-height: 1.38;

      button {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        font: inherit;
        line-height: inherit;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: background-color 0.15s ease;
        text-align: left;

        @media screen and (min-width: 721px) {
          border-radius: 8px;
        }

        &:hover {
          background-color: #ECECEF;
        }
      }

      .title {
        display: block;
        margin: 14px 24px 12px;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .date {
        display: block;
        font-size: 12px;
        color: #999999;
        padding-bottom: 14px;
        margin: 12px 24px 0;
        border-bottom: 1px solid #ECECEF;
      }
    }
  }

  .service-menu {
    li {
      min-height: 52px;
      font-size: 16px;
      color: #191919;
      line-height: 1.38;

      button {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        font: inherit;
        line-height: inherit;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: background-color 0.15s ease;

        @media screen and (min-width: 721px) {
          border-radius: 8px;
        }

        &:hover {
          background-color: #ECECEF;
        }
      }

      .value-left {
        float: left;
        margin: 14px 24px;
        height: 22px;
      }

      .value-right {
        float: right;
        color: #3154FF;
        margin: 14px 24px;
        height: 22px;
      }

      .content {
        margin: 0 24px;
        border-bottom: 1px solid transparent;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s ease,
                    border-bottom-color 0.15s ease;

        .wysiwyg {
          padding-bottom: 24px;
        }
      }

      &::after {
        content: "";
        display: block;
        float: none;
        clear: both;
      }

      &.open {
        .value-left {
          font-weight: bold;
        }

        .content {
          max-height: 1000px;
          border-bottom-color: #DCDCE0;
          transition: max-height 0.5s ease,
                      border-bottom-color 0.15s ease;
        }
      }
    }
  }
}

.term {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 56px);
  overflow: auto;
  background: #FFFFFF;
}