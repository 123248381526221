.board-post-read {
  height: 100%;
  overflow: auto;
  scroll-behavior: smooth;

  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .post-area-wrap {
    min-height: calc(100vh - 175px);
    padding-bottom: 60px;
  }

  .post-area {
    border-bottom: 8px solid #F6F6FA;

    .board {
      padding: 24px 24px 0 24px;
      font-size: 12px;
      color: #999999;

      .date {
        float: right;
      }
    }

    .author {
      padding: 12px 24px;
      font-weight: bold;
      font-size: 12px;
      color: #767676;

      .author-wrap {
        cursor: pointer;

        .ui-country {
          margin-right: 10px;

          .ui-icon i {
            cursor: pointer;
          }
        }
      }
    }

    .title {
      padding: 0 24px;
      line-height: 1.4;
      color: #191919;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .content {
      padding: 0 24px;
      line-height: 1.4;
      color: #191919;
    }

    .files {
      position: relative;
      padding-top: 375px;
      overflow: hidden;

      .thumbnail {
        position: absolute;
        top: -375px;
        left: 0;
        width: 100%;
        height: 375px;
        white-space: nowrap;
        transition: left 0.5s ease;

        &.swiping {
          transition: none;
        }

       .thumb {
         display: inline-block;
         width: 100%;
         height: 100%;

         .th {
           height: 100%;
           background-repeat: no-repeat;
           background-position: center center;
           background-size: contain;
           cursor: pointer;
         }
       }
      }

      .files-navigation {
        text-align: center;
        padding: 4px 0 12px;

        .bullet {
          border: none;
          padding: 0;
          margin: 0 1px 0 2px;
          border-radius: 10px;
          width: 6px;
          height: 6px;
          background-color: #DCDCE0;
          cursor: pointer;
          transition: background-color 0.15s ease;

          &.active {
            background-color: #3154FF;
          }
        }
      }

      .files-arrow {
        position: absolute;
        top: calc(50% - 16px);
        left: 50%;
        white-space: nowrap;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity 0.5s ease;

        @media screen and (min-width: 800px) {
          opacity: 1;
        }

        .ui-icon:first-child {
          margin-right: 700px;
        }
      }
    }

    .meta {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      align-items: center;

      .button {
        width: 25%;

        button {
          width: 100%;
          height: 54px;
          border: none;
          background: transparent;
          cursor: pointer;
          margin: 0;
          padding: 0;
          font-size: 16px;
          color: #78787A;

          .ui-icon {
            margin-right: 3px;
          }

          &.active {
            color: #3154FF;
          }
        }
      }

      .gap {
        flex-basis: 1px;
        height: 11px;
        background-color: #DCDCE0;
      }
    }
  }

  .comment-area {
    margin-bottom: 60px;

    .comment-title {
      line-height: 1.5;
      font-weight: bold;
      font-size: 16px;
      padding: 12px 24px 0;
    }

    .comment-list {
      position: relative;
      margin-top: -6px;

      li {
        // position: relative;
        padding: 0 24px;

        .reply-icon {
          display: inline-block;
          vertical-align: top;
          width: 30px;
          margin: 18px 0 0 6px;
        }

        .children {
          &::before {
            content: "";
            display: block;
            width: calc(100% - 36px);
            margin-left: 36px;
            height: 1px;
            background-color: #F6F6FA;
          }

          li {
            padding: 0;

            .board-post-comment-item {
              display: inline-block;
              vertical-align: top;
              width: calc(100% - 36px);
            }
          }
        }

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #F6F6FA;
        }
      }
    }
  }

  .board-post-comment {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 32px);
    padding: 8px 16px calc(env(safe-area-inset-bottom) * 0.8 + 8px);
    background-color: #f6f6fa;
  }

  .post-create-wrap {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 56px - env(safe-area-inset-top));
    padding-top: calc(env(safe-area-inset-top));
    overflow: auto;
    background: #FFFFFF;
    z-index: 10000;
  }

  .board-post-profile {
    z-index: 100100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: #FFFFFF;
  }

  .board-post-image {
    .meta {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      align-items: center;

      .button {
        width: 25%;

        button {
          width: 100%;
          height: 54px;
          border: none;
          background: transparent;
          cursor: pointer;
          margin: 0;
          padding: 4px 0 0 0;
          font-size: 16px;
          color: #FFFFFF;

          .ui-icon {
            margin-right: 3px;
          }

          &.active {
            color: #3154FF;
          }
        }
      }

      .gap {
        flex-basis: 1px;
        height: 11px;
        background-color: #767676;
      }
    }
  }
}