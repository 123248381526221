.check-item {
  position: relative;

  &::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #ECECEF;
    margin: 0 25px;
  }

  .check-text {
    cursor: pointer;
    padding: 0 47px 0 25px;

    .item-title {
      color: #191919;
      line-height: 38px;
      font-size: 16px;
      min-height: 22px;
      transition: color 0.15s ease;

      b {
        font-weight: bold;
      }

      &.with-desc {
        line-height: 1.43;
        font-size: 14px;
        padding-top: 15px;
      }
    }

    .item-description {
      color: #999999;
      font-size: 12px;
      padding: 3px 0 18px 0;
    }

    &.checked {
      .item-title {
        color: #3154FF;
      }
    }
  }

  .ui-check {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(calc(-50% + 1px));
  }
}