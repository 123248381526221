.my-page {
  .my-profile {
    &::after {
      content: "";
      display: block;
      height: 1px;
      background-color: #DADADF;
      transition: margin 0.15s ease;

      @media (min-width: 720px) {
        margin: 0 24px;
      }
    }

    .login {
      padding: 15px 0;
    }

    .profile {
      padding: 0 24px;
      cursor: pointer;

      .profile-image {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
        height: 70px;
        border-radius: 8px;
        margin: 21px 0;
        background-size: cover;
        background-position: center center;
      }

      .profile-info {
        display: inline-block;
        vertical-align: middle;
        margin: 0 8px;
        width: calc(100% - 190px);

        .nickname {
          font-size: 16px;
          font-weight: bold;
          line-height: 1.38;
          color: #191919;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }

      .profile-heart {
        display: inline-block;
        vertical-align: middle;
        width: 103px;
        text-align: right;
        margin-top: 2px;

        .ui-icon {
          margin-right: -2px;
        }
      }
    }

    .purchase-list {
      margin: -12px 0 24px;

      .purchase {
        border: 1px solid #DADADF;
        margin: 12px 0;
        border-radius: 8px;
        padding: 18px 12px;

        .name {
          color: #191919;
          font-size: 14px;
          margin-bottom: 12px;
        }

        .date {
          color: #999999;
          font-size: 12px;
        }
      }
    }
  }

  .my-menu {
    margin-top: 1px;

    button {
      appearance: none;
      background: transparent;
      border: none;
      cursor: pointer;
      line-height: 52px;
      height: 52px;
      width: 100%;
      color: #191919;
      font-size: 16px;
      text-align: left;
      font-weight: bold;
      padding: 0 24px;
      box-sizing: border-box;
      transition: background-color 0.15s ease;

      @media screen and (min-width: 721px) {
        border-radius: 8px;
      }

      &:hover {
        background-color: #ECECEF;
      }
    }
  }
}