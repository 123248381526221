.navbar {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;

  .navbar-back {
    position: absolute;
    z-index: 10;
    top: 7px;
    left: 0;
    height: 28px;
    vertical-align: middle;
    margin-right: -15px;

    .ui-icon {
      margin-left: 16px;
    }
  }

  .navbar-title {
    position: relative;
    display: inline-block;
    height: 28px;
    margin: 0 6px;
    flex: 1;
    transition: margin-left 0.5s ease;

    &.has-back {
      margin-left: 36px;
    }

    h1 {
      position: absolute;
      width: 100%;
      font-size: 18px;
      line-height: 26px;
      font-weight: bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.navbar-title-enter {
        opacity: 0;
        transform: translateX(50%);
      }

      &.navbar-title-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.5s ease,
                    transform 0.5s ease;
      }

      &.navbar-title-exit {
        opacity: 1;
        transform: translateX(0);
      }

      &.navbar-title-exit-active {
        opacity: 0;
        transform: translateX(-15%);
        transition: opacity 0.5s ease,
                    transform 0.5s ease;
      }
    }

    .navbar-title-wrap {
      margin-left: 16px;
    }
  }

  .navbar-icons {
    float: right;
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    flex: 0 0 auto;

    .ui-icon + .ui-icon {
      margin-left: calc(24px - 1ch);
    }

    a,
    button {
      font-size: 14px;
      line-height: 1.43;
      text-decoration: none;
      cursor: pointer;
      color: #767676;
      border: none;
      background: transparent;
      padding: 0;
      margin: 0;
    }
  }
}

.backward {
  .navbar {
    .navbar-title {
      h1 {
        &.navbar-title-enter {
          transform: translateX(-25%);
        }

        &.navbar-title-enter-active {
          transform: translateX(0);
        }

        &.navbar-title-exit {
          transform: translateX(0);
        }

        &.navbar-title-exit-active {
          transform: translateX(50%);
        }
      }
    }
  }
}