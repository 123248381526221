@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Noto+Sans+KR:wght@400;700&family=Noto+Sans+TC:wght@400;700&display=swap');
@import 'reset.scss';

* {
  -webkit-tap-highlight-color:rgba(255,255,255,0);
  -webkit-touch-callout: none;
}

body {
  background-color: #ffffff;
  font-family: 'Noto Sans KR', 'Noto Sans JP', 'Noto Sans TC', sans-serif;
  word-break:keep-all;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-text-size-adjust:none;
}

.a11y-invisible {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
