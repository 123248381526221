.board-post-write {
  .title {
    border-bottom: 1px solid #DCDCE0;

    textarea {
      padding: 12px 24px;
      height: 24px;
      line-height: 1.72;
      border: none;
      width: calc(100% - 48px);
      resize: none;
      font-weight: bold;
      font-size: 16px;
      outline: none;
      overflow-y: hidden;

      &::placeholder {
        color: #999999;
      }
    }
  }

  .content {
    position: relative;

    textarea {
      padding: 12px 24px;
      line-height: 1.72;
      border: none;
      width: calc(100% - 48px);
      max-height: calc(100vh - 277px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      resize: none;
      font-size: 14px;
      outline: none;

      &::placeholder {
        color: #999999;
      }
    }

    .description {
      position: absolute;
      top: 56px;
      left: 24px;
      width: calc(100% - 72px);
      padding: 22px 12px 18px;
      border-radius: 8px;
      background-color: #f8f8f8;
      color: #999999;

      h5 {
        font-size: 14px;
        font-weight: bold;
      }

      ol {
        font-size: 14px;
        margin-top: 12px;

        li {
          line-height: 1.7;
          list-style: decimal;
          margin-left: 2ch;
        }
      }
    }
  }

  .files {
    position: relative;
    height: 146px;
    transition: height 0.2s ease;
    background-color: #DCDCE0;
    padding-bottom: calc(env(safe-area-inset-bottom));
  }
}