.with-loader {
  .loading-content {
    transition: opacity 1.2s steps(1, end);
  }

  .splash-loader {
    z-index: 100000000000;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3154FF;
    transform: translateY(100%);
    transition: transform 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    .ui-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.loader-enter {
    .loading-content {
      position: absolute;
      width: 100%;
      min-height: 100vh;
      z-index: 2;
      opacity: 0;
    }

    .splash-loader {
      transform: translateY(100%);
    }
  }

  &.loader-enter-active {
    .loading-content {
      opacity: 1;
    }

    .splash-loader {
      transform: translateY(0);
    }
  }

  &.loader-exit {
    .loading-content {
      position: absolute;
      width: 100%;
      min-height: 100vh;
      z-index: 1;
      opacity: 1;
    }

    .splash-loader {
      transform: translateY(0);
    }
  }

  &.loader-exit-active {
    .loading-content {
      opacity: 0;
    }

    .splash-loader {
      transform: translateY(100%);
    }
  }

  &.loader-enter,
  &.loader-exit {
    .loading-content {
      .layout-header {
        &.slide-enter {
          display: none;
        }

        &.slide-exit-active {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .layout-content {
        .layout-container {
          &.slide-enter {
            display: none;
          }

          &.slide-exit-active {
            opacity: 1;
            transform: translateX(-50%);
          }
        }
      }

      .layout-footer {
        &.slide-enter {
          display: none;
        }

        &.slide-exit-active {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}