.talk-room {
  height: 100%;
  overflow: auto;
  // scroll-behavior: smooth;
  background-color: #E2E2EA;

  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .room-header {
    height: 52px;
    padding-top: env(safe-area-inset-top);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    background-color: #E2E2EA;

    .room-header-wrap {
      position: relative;
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .room-back {
      height: 28px;
      vertical-align: middle;

      .ui-icon {
        margin-left: 16px;
      }
    }

    .room-title {
      position: relative;
      display: inline-block;
      height: 28px;
      margin: 0 8px;
      flex: 1 1 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      h1 {
        position: absolute;
        font-size: 18px;
        line-height: 26px;
        font-weight: bold;

        em {
          color: #999999;
          font-weight: normal;
          margin-left: 5px;
        }
      }
    }

    .room-icons {
      float: right;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
      flex: 0 0 auto;
    }
  }

  .room-content {
    min-height: calc(100vh - 52px - 59px - 2px - env(safe-area-inset-top));
    padding: 1px 0;

    .chat-system {
      text-align: center;
      margin: 14px 0;

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      .msg {
        display: inline-block;
        color: #FFFFFF;
        font-size: 10px;
        line-height: 1.6;
        padding: 4px 11px;
        border-radius: 12.5px;
        background-color: rgba(118, 118, 118, 0.35);
      }
    }

    .chat-loader {
      padding: 5px 15px 15px;
      text-align: right;
    }

    .chat-item {
      margin: 0 6px;

      &::after {
        content: "";
        display: block;
        clear: both;
      }

      .chat-profile {
        float: left;
        position: relative;
        padding-right: 4px;
        cursor: pointer;

        .user-profile {
          display: block;
          width: 34px;
          height: 34px;
          border-radius: 4px;
          background-size: cover;
          background-position: center;
        }

        .ui-country {
          position: absolute;
          right: 0;
          bottom: -4px;

          .ui-icon i {
            width: 16px;
            height: 10px;
            cursor: pointer;
          }
        }
      }

      .chat-info {
        float: left;
        margin-bottom: 12px;
        width: 80%;

        h6 {
          font-size: 12px;
          color: #191919;
          margin-bottom: 5px;
        }

        .chat-message {
          .msg {
            position: relative;
            border-radius: 8px;
            background-color: #FFFFFF;
            max-width: 80%;
            font-size: 12px;
            line-height: 1.5;
            text-align: left;
            color: #191919;
            padding: 8px;
            display: inline-block;
            vertical-align: bottom;

            .wysiwyg {
              font-size: inherit;
              line-height: inherit;

              a {
                color: inherit;
              }
            }

            img {
              max-width: 100%;
              border-radius: 8px;
              vertical-align: middle;
            }

            .image-loader {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            &.image {
              width: 80%;
              padding: 0;
              background-color: transparent !important;

              .image-wrap {
                height: 0;

                img {
                  margin-top: -21px;
                }
              }
            }

            .msg-original {
              color: #999999;
              border-bottom: 1px solid #DADADF;
              padding-bottom: 7px;
              margin-bottom: 5px;
            }

            .msg-blocked {
              color: #999999;

              &.show {
                border-bottom: 1px solid #DADADF;
                padding-bottom: 7px;
                margin-bottom: 5px;
              }
            }
          }

          .msg-translation {
            border: none;
            background-color: #FFFFFF;
            width: 28px;
            height: 28px;
            border-radius: 28px;
            margin-left: 4px;
            padding: 4px;
            text-align: center;
            cursor: pointer;
            transition: background-color 0.1s ease;

            .ui-icon {
              cursor: pointer;
            }

            &:hover {
              background-color: #F6F6FA;
            }
          }

          em {
            position: relative;
            font-size: 10px;
            line-height: 2.1;
            color: #999999;
            display: inline-block;
            vertical-align: bottom;
            padding: 0 5px;

            .chat-uploading {
              position: absolute;
              bottom: 0;
              right: 4px;
              width: 33px;
              height: 17px;
              padding: 0 2px;
              border-radius: 8px;
              background-color: #FFFFFF;
              white-space: nowrap;

              .ui-icon {
                padding: 0 2px;
                margin-top: -4px;
              }
            }
          }
        }
      }

      &.me {
        .chat-profile {
          display: none;
        }

        .chat-info {
          float: right;
          text-align: right;

          h6 {
            display: none;
          }

          .chat-message {
            position: relative;

            .msg {
              color: #FFFFFF;
              background-color: #3154FF;
            }
          }
        }
      }
    }
  }

  .room-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: calc(100% - 32px);
    padding: 8px 16px calc(env(safe-area-inset-bottom) * 0.8 + 8px);
    background-color: #f6f6fa;
  }

  .room-image {
    position: fixed;
    z-index: 200;

    .button {
      width: 100%;

      button {
        width: 100%;
        height: 54px;
        border: none;
        background: transparent;
        cursor: pointer;
        margin: 0;
        padding: 4px 0 0 0;
        font-size: 16px;
        color: #FFFFFF;

        .ui-icon {
          margin-right: 3px;
        }

        &.active {
          color: #3154FF;
        }
      }
    }
  }

  .room-side-dim {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }

  .room-side {
    position: fixed;
    z-index: 100000;
    bottom: 0;
    right: 0;
    transform: translateX(0);
    background: #FFFFFF;
    width: calc(50vw + 260px);
    color: #191919;
    height: 100vh;
    transition: transform 0.2s ease;

    @media (max-width: 720px) {
      width: calc(100vw - 100px);
    }

    &.side-enter {
      transform: translateX(100%);
    }

    &.side-enter-active {
      transform: translateX(0);
    }

    &.side-exit {
      transform: translateX(0);
    }

    &.side-exit-active {
      transform: translateX(100%);
    }

    .room-side-wrap {
      position: relative;
      width: 620px;
      height: calc(100% - 60px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
      padding-top: env(safe-area-inset-top);
      padding-bottom: 60px;

      @media (max-width: 720px) {
        width: calc(100vw - 100px);
      }

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        display: block;
        width: calc(50vw + 260px);
        height: 60px;
        background-color: #F6F6FA;

        @media (max-width: 720px) {
          width: calc(100vw - 100px);
        }
      }
    }

    .side-title {
      padding: 23px 17px 14px;
      color: #999;
      line-height: 1.38;
      font-size: 16px;
    }

    .side-members {
      border-top: 1px solid #ECECEF;

      .members-title {
        padding: 14px 17px;
        font-size: 16px;
        line-height: 1.38;
        color: #191919;
        font-weight: bold;
      }

      .user-profiles {
      }

      .user-profile {
        display: flex;
        align-items: center;
        padding: 7px 17px 7px 8px;
        margin-left: 9px;
        width: 100%;
        box-sizing: border-box;
        text-align: left;
        background-color: transparent;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.1s ease;

        @media (max-width: 720px) {
          border-radius: 0;
          padding-left: 17px;
          margin-left: 0;
        }

        &:hover {
          background-color: #ECECEF;
        }

        .profile-image {
          flex: 0 0 40px;
          width: 40px;
          height: 40px;
          border-radius: 6px;
          background-size: cover;
          background-position: center;

          em {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 6px;
            background-color: rgba(25, 25, 25, 0.5);
            color: #FFFFFF;
            line-height: 37px;
            text-align: center;
            font-size: 12px;
          }
        }

        .profile-name {
          flex: 1 1 auto;
          margin: 0 11px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.71;
        }

        .profile-country {
          flex: 0 0 50px;
          text-align: right;

          .ui-country {
            margin-top: 2px;
          }
        }
      }
    }

    .side-buttons {
      z-index: 2;
      position: absolute;
      bottom: calc(-1 * env(safe-area-inset-bottom));
      padding-bottom: calc(env(safe-area-inset-bottom));
      background-color: #F6F6FA;
      left: 0;
      width: 100%;
      height: 60px;

      button {
        border: none;
        width: 50%;
        height: 100%;
        background-color: #F6F6FA;
        text-align: center;
        transition: background-color 0.1s ease;
        cursor: pointer;

        &:hover {
          background-color: #ECECEF;
        }

        .ui-icon {
          cursor: pointer;
        }
      }
    }
  }

  .room-setting {
    z-index: 100100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: #FFFFFF;

    .setting-header {
      position: relative;
      height: 52px;
      padding-top: env(safe-area-inset-top);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .setting-back {
      height: 28px;
      vertical-align: middle;

      .ui-icon {
        margin-left: 16px;
      }
    }

    .setting-title {
      position: relative;
      display: inline-block;
      height: 28px;
      margin: 0 8px;
      flex: 1 1 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      h1 {
        position: absolute;
        font-size: 18px;
        line-height: 26px;
        font-weight: bold;
      }
    }

    .setting-body {
      ul {
        margin-top: 1px;

        button {
          appearance: none;
          background: transparent;
          border: none;
          cursor: pointer;
          line-height: 52px;
          height: 52px;
          width: 100%;
          color: #191919;
          font-size: 16px;
          text-align: left;
          padding: 0 24px;
          box-sizing: border-box;
          transition: background-color 0.15s ease;

          @media screen and (min-width: 721px) {
            border-radius: 8px;
          }

          &:hover {
            background-color: #ECECEF;
          }
        }
      }

      .rename-setting {

      }
    }
  }

  .room-profile {
    z-index: 100100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: #FFFFFF;
  }
}