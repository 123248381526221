.board-post-comment-item {
  .author {
    padding: 24px 0 12px;
    margin-bottom: -12px;
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #191919;

    &.blocked {
      color: #767676;
      opacity: 0.3;
    }

    .author-wrap {
      cursor: pointer;

      .ui-country {
        margin-right: 10px;

        .ui-icon i {
          cursor: pointer;
        }
      }
    }

    .hamburger {
      float: right;

      .ui-icon {
        margin-left: 8px;
      }
    }
  }

  .content {
    display: block;
    line-height: 1.4;
    padding-top: 12px;

    .title {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #191919;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .summary {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #767676;
      font-size: 14px;
      margin-bottom: 18px;
    }
  }

  .reported {
    margin: 10px 0;
    color: #BBBBBB;
  }

  .meta {
    display: block;
    color: #999999;
    font-size: 12px;
    padding-top: 6px;
    padding-bottom: 18px;

    &:after {
      content: "";
      display: block;
      float: none;
      clear: both;
    }

    button {
      padding: 0;
      margin: 0;
      background: transparent;
      border: none;
      color: inherit;
      cursor: pointer;
    }

    .count {
      .comment {
        margin-left: 10px;
      }

      .ui-icon {
        margin-right: 1px;
      }
    }

    .date {
      float: right;
    }
  }
}
