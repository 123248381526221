.ui-button {
  position: relative;
  display: block;
  transition: bottom 0.5s ease;

  button {
    border: none;
    font-size: 16px;
    line-height: 22px;
    border-radius: 8px;
    text-align: center;
    width: calc(100% - 48px);
    padding: 16px 20px 15px 20px;
    margin: 14px 24px;
    cursor: pointer;
    transition: background-color 0.15s ease,
                color 0.15s ease,
                width 0.15s ease,
                height 0.15s ease,
                font-size 0.15s ease,
                line-height 0.15s ease,
                margin 0.15s ease,
                opacity 0.15s ease,
                box-shadow 0.15s ease,
                border-radius 0.15s ease;

    &:focus {
      outline: 0;
      opacity: 0.5;
    }
  }

  &.small {
    display: inline-block;

    button {
      margin: 3px;
      width: auto;
      height: 30px;
      border-radius: 15px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 11px;
      vertical-align: middle;

      &:focus {
        opacity: 1;
      }

      .ui-icon {
        vertical-align: top;
        height: 30px;

        i {
          vertical-align: top;
          height: 30px;
          margin-left: -8px;
        }
      }
    }

    &.white {
      button {
        color: #767676;
      }
    }
  }

  &.blue {
    button {
      background-color: #3154FF;
      color: #FFFFFF;

      &:disabled {
        background-color: #ECECEF;
        color: #767676;
      }
    }
  }

  &.white {
    button {
      background-color: #FFFFFF;
      color: #191919;
      box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.08);

      &:disabled {
        background-color: #ECECEF;
        color: #767676;
        box-shadow: none;
      }
    }
  }

  &.fixed {
    position: fixed !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(calc(-1 * env(safe-area-inset-bottom)));
    width: 100%;
    z-index: 9999;

    button {
      margin: 24px;
    }

    &.full {
      left: 0;
      transform: translateX(0) translateY(calc(-1 * env(safe-area-inset-bottom)));

      button {
        margin: 0;
      }
    }
  }

  &.full {
    width: 100%;

    button {
      width: 100%;
      margin: 0;
      border-radius: 0;
    }
  }

  &.floating {
    position: fixed !important;
    height: 0;
    overflow: visible;
    width: 100% !important;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%) translateY(calc(-1 * env(safe-area-inset-bottom)));
    text-align: right;
    z-index: 9999;
    transition: bottom 0.5s ease;

    button {
      margin: 0 24px 24px 0;
      padding: 8px;
      width: 44px;
      height: 44px;

      .ui-icon {
        margin: -8px;
        padding: 8px;
      }
    }
  }
}
