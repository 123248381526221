.ui-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border: none;
  background: transparent;
  cursor: default;

  &.clickable {
    cursor: pointer;
  }

  &.has-hover,
  &.has-active {
    i {
      transition: opacity 0.1s ease;

      &.hover-icon,
      &.active-icon {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }

  &.has-hover:hover {
    i {
      opacity: 0;

      &.hover-icon {
        opacity: 1;
      }
    }
  }

  &.has-active:active {
    i {
      opacity: 0;

      &.hover-icon {
        opacity: 0;
      }

      &.active-icon {
        opacity: 1;
      }
    }
  }

  &.button {
    padding: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    background: #FFFFFF;
  }

  .icon-badge {
    position: absolute;
    top: -5px;
    left: 14px;
    line-height: 1.6;
    font-size: 10px;
    color: #FFFFFF;
    padding: 0 5px;
    background: #3154FF;
    border-radius: 8px;
    min-width: 6px;
    text-align: center;
  }

  i {
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &.size--large {
      width: 28px !important;
      height: 28px !important;
    }

    &.size--medium {
      width: 22px !important;
      height: 22px !important;
    }

    &.size--small {
      width: 18px !important;
      height: 18px !important;
    }

    &.icon--flag {
      width: 21px;
      height: 12px;
      background-image: url(../../assets/images/flag--void.svg);


      &.AD {
        background-image: url(../../assets/images/flag--ad.svg);
      }

      &.AE {
        background-image: url(../../assets/images/flag--ae.svg);
      }

      &.AF {
        background-image: url(../../assets/images/flag--af.svg);
      }

      &.AG {
        background-image: url(../../assets/images/flag--ag.svg);
      }

      &.AI {
        background-image: url(../../assets/images/flag--ai.svg);
      }

      &.AL {
        background-image: url(../../assets/images/flag--al.svg);
      }

      &.AM {
        background-image: url(../../assets/images/flag--am.svg);
      }

      &.AO {
        background-image: url(../../assets/images/flag--ad.svg);
      }

      &.AQ {
        background-image: url(../../assets/images/flag--aq.svg);
      }

      &.AR {
        background-image: url(../../assets/images/flag--ar.svg);
      }

      &.AS {
        background-image: url(../../assets/images/flag--as.svg);
      }

      &.AT {
        background-image: url(../../assets/images/flag--at.svg);
      }

      &.AU {
        background-image: url(../../assets/images/flag--au.svg);
      }

      &.AW {
        background-image: url(../../assets/images/flag--aw.svg);
      }

      &.AX {
        background-image: url(../../assets/images/flag--ax.svg);
      }

      &.AZ {
        background-image: url(../../assets/images/flag--az.svg);
      }

      &.BA {
        background-image: url(../../assets/images/flag--ba.svg);
      }

      &.BB {
        background-image: url(../../assets/images/flag--bb.svg);
      }

      &.BD {
        background-image: url(../../assets/images/flag--bd.svg);
      }

      &.BE {
        background-image: url(../../assets/images/flag--be.svg);
      }

      &.BF {
        background-image: url(../../assets/images/flag--bf.svg);
      }

      &.BG {
        background-image: url(../../assets/images/flag--bg.svg);
      }

      &.BH {
        background-image: url(../../assets/images/flag--bh.svg);
      }

      &.BI {
        background-image: url(../../assets/images/flag--bi.svg);
      }

      &.BJ {
        background-image: url(../../assets/images/flag--bj.svg);
      }

      &.BL {
        background-image: url(../../assets/images/flag--bl.svg);
      }

      &.BM {
        background-image: url(../../assets/images/flag--bm.svg);
      }

      &.BN {
        background-image: url(../../assets/images/flag--bn.svg);
      }

      &.BO {
        background-image: url(../../assets/images/flag--bo.svg);
      }

      &.BQ {
        background-image: url(../../assets/images/flag--bq.svg);
      }

      &.BR {
        background-image: url(../../assets/images/flag--br.svg);
      }

      &.BS {
        background-image: url(../../assets/images/flag--bs.svg);
      }

      &.BT {
        background-image: url(../../assets/images/flag--bt.svg);
      }

      &.BV {
        background-image: url(../../assets/images/flag--bv.svg);
      }

      &.BW {
        background-image: url(../../assets/images/flag--bw.svg);
      }

      &.BY {
        background-image: url(../../assets/images/flag--by.svg);
      }

      &.BZ {
        background-image: url(../../assets/images/flag--bz.svg);
      }

      &.CA {
        background-image: url(../../assets/images/flag--ca.svg);
      }

      &.CC {
        background-image: url(../../assets/images/flag--cc.svg);
      }

      &.CD {
        background-image: url(../../assets/images/flag--cd.svg);
      }

      &.CF {
        background-image: url(../../assets/images/flag--cf.svg);
      }

      &.CG {
        background-image: url(../../assets/images/flag--cg.svg);
      }

      &.CH {
        background-image: url(../../assets/images/flag--ch.svg);
      }

      &.CI {
        background-image: url(../../assets/images/flag--ci.svg);
      }

      &.CK {
        background-image: url(../../assets/images/flag--ck.svg);
      }

      &.CL {
        background-image: url(../../assets/images/flag--cl.svg);
      }

      &.CM {
        background-image: url(../../assets/images/flag--cm.svg);
      }

      &.CN {
        background-image: url(../../assets/images/flag--cn.svg);
      }

      &.CO {
        background-image: url(../../assets/images/flag--co.svg);
      }

      &.CR {
        background-image: url(../../assets/images/flag--cr.svg);
      }

      &.CU {
        background-image: url(../../assets/images/flag--cu.svg);
      }

      &.CV {
        background-image: url(../../assets/images/flag--cv.svg);
      }

      &.CW {
        background-image: url(../../assets/images/flag--cw.svg);
      }

      &.CX {
        background-image: url(../../assets/images/flag--cx.svg);
      }

      &.CY {
        background-image: url(../../assets/images/flag--cy.svg);
      }

      &.CZ {
        background-image: url(../../assets/images/flag--cz.svg);
      }

      &.DE {
        background-image: url(../../assets/images/flag--de.svg);
      }

      &.DJ {
        background-image: url(../../assets/images/flag--dj.svg);
      }

      &.DK {
        background-image: url(../../assets/images/flag--dk.svg);
      }

      &.DM {
        background-image: url(../../assets/images/flag--dm.svg);
      }

      &.DO {
        background-image: url(../../assets/images/flag--do.svg);
      }

      &.DZ {
        background-image: url(../../assets/images/flag--dz.svg);
      }

      &.EC {
        background-image: url(../../assets/images/flag--ec.svg);
      }

      &.EE {
        background-image: url(../../assets/images/flag--ee.svg);
      }

      &.EG {
        background-image: url(../../assets/images/flag--eg.svg);
      }

      &.EH {
        background-image: url(../../assets/images/flag--eh.svg);
      }

      &.ER {
        background-image: url(../../assets/images/flag--er.svg);
      }

      &.ES {
        background-image: url(../../assets/images/flag--es.svg);
      }

      &.ET {
        background-image: url(../../assets/images/flag--et.svg);
      }

      &.FI {
        background-image: url(../../assets/images/flag--fi.svg);
      }

      &.FJ {
        background-image: url(../../assets/images/flag--fj.svg);
      }

      &.FK {
        background-image: url(../../assets/images/flag--fk.svg);
      }

      &.FM {
        background-image: url(../../assets/images/flag--fm.svg);
      }

      &.FO {
        background-image: url(../../assets/images/flag--fo.svg);
      }

      &.FR {
        background-image: url(../../assets/images/flag--fr.svg);
      }

      &.GA {
        background-image: url(../../assets/images/flag--ga.svg);
      }

      &.GB {
        background-image: url(../../assets/images/flag--gb.svg);
      }

      &.GD {
        background-image: url(../../assets/images/flag--gd.svg);
      }

      &.GE {
        background-image: url(../../assets/images/flag--ge.svg);
      }

      &.GF {
        background-image: url(../../assets/images/flag--gf.svg);
      }

      &.GG {
        background-image: url(../../assets/images/flag--gg.svg);
      }

      &.GH {
        background-image: url(../../assets/images/flag--gh.svg);
      }

      &.GI {
        background-image: url(../../assets/images/flag--gi.svg);
      }

      &.GL {
        background-image: url(../../assets/images/flag--gl.svg);
      }

      &.GM {
        background-image: url(../../assets/images/flag--gm.svg);
      }

      &.GN {
        background-image: url(../../assets/images/flag--gn.svg);
      }

      &.GP {
        background-image: url(../../assets/images/flag--gp.svg);
      }

      &.GQ {
        background-image: url(../../assets/images/flag--gq.svg);
      }

      &.GR {
        background-image: url(../../assets/images/flag--gr.svg);
      }

      &.GS {
        background-image: url(../../assets/images/flag--gs.svg);
      }

      &.GT {
        background-image: url(../../assets/images/flag--gt.svg);
      }

      &.GU {
        background-image: url(../../assets/images/flag--gu.svg);
      }

      &.GW {
        background-image: url(../../assets/images/flag--gw.svg);
      }

      &.GY {
        background-image: url(../../assets/images/flag--gy.svg);
      }

      &.HK {
        background-image: url(../../assets/images/flag--hk.svg);
      }

      &.HN {
        background-image: url(../../assets/images/flag--hn.svg);
      }

      &.HR {
        background-image: url(../../assets/images/flag--hr.svg);
      }

      &.HT {
        background-image: url(../../assets/images/flag--ht.svg);
      }

      &.HU {
        background-image: url(../../assets/images/flag--hu.svg);
      }

      &.ID {
        background-image: url(../../assets/images/flag--id.svg);
      }

      &.IE {
        background-image: url(../../assets/images/flag--ie.svg);
      }

      &.IL {
        background-image: url(../../assets/images/flag--il.svg);
      }

      &.IM {
        background-image: url(../../assets/images/flag--im.svg);
      }

      &.IN {
        background-image: url(../../assets/images/flag--in.svg);
      }

      &.IO {
        background-image: url(../../assets/images/flag--io.svg);
      }

      &.IQ {
        background-image: url(../../assets/images/flag--iq.svg);
      }

      &.IR {
        background-image: url(../../assets/images/flag--ir.svg);
      }

      &.IS {
        background-image: url(../../assets/images/flag--is.svg);
      }

      &.IT {
        background-image: url(../../assets/images/flag--it.svg);
      }

      &.JE {
        background-image: url(../../assets/images/flag--je.svg);
      }

      &.JM {
        background-image: url(../../assets/images/flag--jm.svg);
      }

      &.JO {
        background-image: url(../../assets/images/flag--jo.svg);
      }

      &.JP {
        background-image: url(../../assets/images/flag--jp.svg);
      }

      &.KE {
        background-image: url(../../assets/images/flag--ke.svg);
      }

      &.KG {
        background-image: url(../../assets/images/flag--kg.svg);
      }

      &.KH {
        background-image: url(../../assets/images/flag--kh.svg);
      }

      &.KI {
        background-image: url(../../assets/images/flag--ki.svg);
      }

      &.KM {
        background-image: url(../../assets/images/flag--km.svg);
      }

      &.KN {
        background-image: url(../../assets/images/flag--kn.svg);
      }

      &.KP {
        background-image: url(../../assets/images/flag--kp.svg);
      }

      &.KR {
        background-image: url(../../assets/images/flag--kr.svg);
      }

      &.KW {
        background-image: url(../../assets/images/flag--kw.svg);
      }

      &.KY {
        background-image: url(../../assets/images/flag--ky.svg);
      }

      &.KZ {
        background-image: url(../../assets/images/flag--kz.svg);
      }

      &.LA {
        background-image: url(../../assets/images/flag--la.svg);
      }

      &.LB {
        background-image: url(../../assets/images/flag--lb.svg);
      }

      &.LC {
        background-image: url(../../assets/images/flag--lc.svg);
      }

      &.LI {
        background-image: url(../../assets/images/flag--li.svg);
      }

      &.LK {
        background-image: url(../../assets/images/flag--lk.svg);
      }

      &.LR {
        background-image: url(../../assets/images/flag--lr.svg);
      }

      &.LS {
        background-image: url(../../assets/images/flag--ls.svg);
      }

      &.LT {
        background-image: url(../../assets/images/flag--lt.svg);
      }

      &.LU {
        background-image: url(../../assets/images/flag--lu.svg);
      }

      &.LV {
        background-image: url(../../assets/images/flag--lv.svg);
      }

      &.LY {
        background-image: url(../../assets/images/flag--ly.svg);
      }

      &.MA {
        background-image: url(../../assets/images/flag--ma.svg);
      }

      &.MC {
        background-image: url(../../assets/images/flag--mc.svg);
      }

      &.MD {
        background-image: url(../../assets/images/flag--md.svg);
      }

      &.ME {
        background-image: url(../../assets/images/flag--me.svg);
      }

      &.MF {
        background-image: url(../../assets/images/flag--mf.svg);
      }

      &.MG {
        background-image: url(../../assets/images/flag--mg.svg);
      }

      &.MH {
        background-image: url(../../assets/images/flag--mh.svg);
      }

      &.MK {
        background-image: url(../../assets/images/flag--mk.svg);
      }

      &.ML {
        background-image: url(../../assets/images/flag--ml.svg);
      }

      &.MM {
        background-image: url(../../assets/images/flag--mm.svg);
      }

      &.MN {
        background-image: url(../../assets/images/flag--mn.svg);
      }

      &.MO {
        background-image: url(../../assets/images/flag--mo.svg);
      }

      &.MP {
        background-image: url(../../assets/images/flag--mp.svg);
      }

      &.MQ {
        background-image: url(../../assets/images/flag--mq.svg);
      }

      &.MR {
        background-image: url(../../assets/images/flag--mr.svg);
      }

      &.MS {
        background-image: url(../../assets/images/flag--ms.svg);
      }

      &.MT {
        background-image: url(../../assets/images/flag--mt.svg);
      }

      &.MU {
        background-image: url(../../assets/images/flag--mu.svg);
      }

      &.MV {
        background-image: url(../../assets/images/flag--mv.svg);
      }

      &.MW {
        background-image: url(../../assets/images/flag--mw.svg);
      }

      &.MX {
        background-image: url(../../assets/images/flag--mx.svg);
      }

      &.MY {
        background-image: url(../../assets/images/flag--my.svg);
      }

      &.MZ {
        background-image: url(../../assets/images/flag--mz.svg);
      }

      &.NA {
        background-image: url(../../assets/images/flag--na.svg);
      }

      &.NC {
        background-image: url(../../assets/images/flag--nc.svg);
      }

      &.NE {
        background-image: url(../../assets/images/flag--ne.svg);
      }

      &.NF {
        background-image: url(../../assets/images/flag--nf.svg);
      }

      &.NG {
        background-image: url(../../assets/images/flag--ng.svg);
      }

      &.NI {
        background-image: url(../../assets/images/flag--ni.svg);
      }

      &.NL {
        background-image: url(../../assets/images/flag--nl.svg);
      }

      &.NO {
        background-image: url(../../assets/images/flag--no.svg);
      }

      &.NP {
        background-image: url(../../assets/images/flag--np.svg);
      }

      &.NR {
        background-image: url(../../assets/images/flag--nr.svg);
      }

      &.NU {
        background-image: url(../../assets/images/flag--nu.svg);
      }

      &.NZ {
        background-image: url(../../assets/images/flag--nz.svg);
      }

      &.OM {
        background-image: url(../../assets/images/flag--om.svg);
      }

      &.PA {
        background-image: url(../../assets/images/flag--pa.svg);
      }

      &.PE {
        background-image: url(../../assets/images/flag--pe.svg);
      }

      &.PF {
        background-image: url(../../assets/images/flag--pf.svg);
      }

      &.PG {
        background-image: url(../../assets/images/flag--pg.svg);
      }

      &.PH {
        background-image: url(../../assets/images/flag--ph.svg);
      }

      &.PK {
        background-image: url(../../assets/images/flag--pk.svg);
      }

      &.PL {
        background-image: url(../../assets/images/flag--pl.svg);
      }

      &.PM {
        background-image: url(../../assets/images/flag--pm.svg);
      }

      &.PN {
        background-image: url(../../assets/images/flag--pn.svg);
      }

      &.PR {
        background-image: url(../../assets/images/flag--pr.svg);
      }

      &.PS {
        background-image: url(../../assets/images/flag--ps.svg);
      }

      &.PT {
        background-image: url(../../assets/images/flag--pt.svg);
      }

      &.PW {
        background-image: url(../../assets/images/flag--pw.svg);
      }

      &.PY {
        background-image: url(../../assets/images/flag--py.svg);
      }

      &.QA {
        background-image: url(../../assets/images/flag--qa.svg);
      }

      &.RE {
        background-image: url(../../assets/images/flag--re.svg);
      }

      &.RO {
        background-image: url(../../assets/images/flag--ro.svg);
      }

      &.RS {
        background-image: url(../../assets/images/flag--rs.svg);
      }

      &.RU {
        background-image: url(../../assets/images/flag--ru.svg);
      }

      &.RW {
        background-image: url(../../assets/images/flag--rw.svg);
      }

      &.SA {
        background-image: url(../../assets/images/flag--sa.svg);
      }

      &.SB {
        background-image: url(../../assets/images/flag--sb.svg);
      }

      &.SC {
        background-image: url(../../assets/images/flag--sc.svg);
      }

      &.SD {
        background-image: url(../../assets/images/flag--sd.svg);
      }

      &.SE {
        background-image: url(../../assets/images/flag--se.svg);
      }

      &.SG {
        background-image: url(../../assets/images/flag--sg.svg);
      }

      &.SH {
        background-image: url(../../assets/images/flag--sh.svg);
      }

      &.SI {
        background-image: url(../../assets/images/flag--si.svg);
      }

      &.SJ {
        background-image: url(../../assets/images/flag--sj.svg);
      }

      &.SK {
        background-image: url(../../assets/images/flag--sk.svg);
      }

      &.SL {
        background-image: url(../../assets/images/flag--sl.svg);
      }

      &.SM {
        background-image: url(../../assets/images/flag--sm.svg);
      }

      &.SN {
        background-image: url(../../assets/images/flag--sn.svg);
      }

      &.SO {
        background-image: url(../../assets/images/flag--so.svg);
      }

      &.SR {
        background-image: url(../../assets/images/flag--sr.svg);
      }

      &.SS {
        background-image: url(../../assets/images/flag--ss.svg);
      }

      &.ST {
        background-image: url(../../assets/images/flag--st.svg);
      }

      &.SV {
        background-image: url(../../assets/images/flag--sv.svg);
      }

      &.SX {
        background-image: url(../../assets/images/flag--sx.svg);
      }

      &.SY {
        background-image: url(../../assets/images/flag--sy.svg);
      }

      &.SZ {
        background-image: url(../../assets/images/flag--sz.svg);
      }

      &.TC {
        background-image: url(../../assets/images/flag--tc.svg);
      }

      &.TD {
        background-image: url(../../assets/images/flag--td.svg);
      }

      &.TF {
        background-image: url(../../assets/images/flag--tf.svg);
      }

      &.TG {
        background-image: url(../../assets/images/flag--tg.svg);
      }

      &.TH {
        background-image: url(../../assets/images/flag--th.svg);
      }

      &.TJ {
        background-image: url(../../assets/images/flag--tj.svg);
      }

      &.TK {
        background-image: url(../../assets/images/flag--tk.svg);
      }

      &.TL {
        background-image: url(../../assets/images/flag--tl.svg);
      }

      &.TM {
        background-image: url(../../assets/images/flag--tm.svg);
      }

      &.TN {
        background-image: url(../../assets/images/flag--tn.svg);
      }

      &.TO {
        background-image: url(../../assets/images/flag--to.svg);
      }

      &.TR {
        background-image: url(../../assets/images/flag--tr.svg);
      }

      &.TT {
        background-image: url(../../assets/images/flag--tt.svg);
      }

      &.TV {
        background-image: url(../../assets/images/flag--tv.svg);
      }

      &.TW {
        background-image: url(../../assets/images/flag--tw.svg);
      }

      &.TZ {
        background-image: url(../../assets/images/flag--tz.svg);
      }

      &.UA {
        background-image: url(../../assets/images/flag--ua.svg);
      }

      &.UG {
        background-image: url(../../assets/images/flag--ug.svg);
      }

      &.UM {
        background-image: url(../../assets/images/flag--um.svg);
      }

      &.US {
        background-image: url(../../assets/images/flag--us.svg);
      }

      &.UY {
        background-image: url(../../assets/images/flag--uy.svg);
      }

      &.UZ {
        background-image: url(../../assets/images/flag--uz.svg);
      }

      &.VA {
        background-image: url(../../assets/images/flag--va.svg);
      }

      &.VC {
        background-image: url(../../assets/images/flag--vc.svg);
      }

      &.VE {
        background-image: url(../../assets/images/flag--ve.svg);
      }

      &.VG {
        background-image: url(../../assets/images/flag--vg.svg);
      }

      &.VI {
        background-image: url(../../assets/images/flag--vi.svg);
      }

      &.VN {
        background-image: url(../../assets/images/flag--vn.svg);
      }

      &.VU {
        background-image: url(../../assets/images/flag--vu.svg);
      }

      &.WF {
        background-image: url(../../assets/images/flag--wf.svg);
      }

      &.WS {
        background-image: url(../../assets/images/flag--ws.svg);
      }

      &.YE {
        background-image: url(../../assets/images/flag--ye.svg);
      }

      &.YT {
        background-image: url(../../assets/images/flag--yt.svg);
      }

      &.ZA {
        background-image: url(../../assets/images/flag--za.svg);
      }

      &.ZM {
        background-image: url(../../assets/images/flag--zm.svg);
      }

      &.ZW {
        background-image: url(../../assets/images/flag--zw.svg);
      }
    }

    &.icon--logo-blue {
      background-image: url(../../assets/images/logo--blue.png);
      width: 241px;
      height: 60px;
    }

    &.icon--logo-white {
      background-image: url(../../assets/images/logo--white.png);
      width: 241px;
      height: 60px;
    }

    &.icon--logo-type-blue {
      background-image: url(../../assets/images/logo--type-blue.png);
      width: 84px;
      height: 18px;
    }

    &.icon--loader {
      background-image: url(../../assets/images/asset--loader.svg);
      width: 34px;
      height: 34px;
    }

    &.icon--loader-logo {
      background-image: url(../../assets/images/asset--loader-logo.svg);
      width: 150px;
      height: 90px;
    }

    &.icon--photo {
      background-image: url(../../assets/images/icon--photo.svg);
      width: 28px;
      height: 28px;

      &.white {
        background-image: url(../../assets/images/icon--photo-white.svg);
      }
    }

    &.icon--eye {
      background-image: url(../../assets/images/icon--eye.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--eye-slash {
      background-image: url(../../assets/images/icon--eye-slash.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--heart {
      background-image: url(../../assets/images/icon--heart.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--write {
      background-image: url(../../assets/images/icon--write.svg);
      width: 28px;
      height: 28px;

      &.white {
        background-image: url(../../assets/images/icon--write-white.svg);
      }
    }

    &.icon--send {
      background-image: url(../../assets/images/icon--send.svg);
      width: 28px;
      height: 28px;

      &.active {
        background-image: url(../../assets/images/icon--send-active.svg);
      }
    }

    &.icon--pencil {
      background-image: url(../../assets/images/icon--pencil.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--chat {
      background-image: url(../../assets/images/icon--chat.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--deny {
      background-image: url(../../assets/images/icon--deny.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--close {
      background-image: url(../../assets/images/icon--close-white.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--close-circle {
      background-image: url(../../assets/images/icon--close-circle.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--search {
      background-image: url(../../assets/images/icon--search.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--ring {
      background-image: url(../../assets/images/icon--ring.svg);
      width: 28px;
      height: 28px;

      &.gray {
        background-image: url(../../assets/images/icon--ring-gray.svg);
      }
    }

    &.icon--talk {
      background-image: url(../../assets/images/icon--talk.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--exit {
      background-image: url(../../assets/images/icon--exit.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--setting {
      background-image: url(../../assets/images/icon--setting.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--fire {
      background-image: url(../../assets/images/icon--fire.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--check-gray {
      background-image: url(../../assets/images/icon--check-gray.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--check-blue {
      background-image: url(../../assets/images/icon--check-blue.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--check-circle-gray {
      background-image: url(../../assets/images/icon--check-circle-gray.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--check-circle-blue {
      background-image: url(../../assets/images/icon--check-circle-blue.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--check-circle-full-blue {
      background-image: url(../../assets/images/icon--check-circle-full-blue.svg);
      width: 20px;
      height: 20px;
    }

    &.icon--star {
      background-image: url(../../assets/images/icon--star.svg);
      width: 18px;
      height: 18px;
    }

    &.icon--bookmark {
      background-image: url(../../assets/images/icon--bookmark.svg);
      width: 28px;
      height: 28px;

      &.active {
        background-image: url(../../assets/images/icon--bookmark-active.svg);
      }
    }

    &.icon--audio {
      background-image: url(../../assets/images/icon--audio.svg);
      width: 30px;
      height: 30px;

      &.white {
        background-image: url(../../assets/images/icon--audio-white.svg);
      }
    }

    &.icon--photo {
      background-image: url(../../assets/images/icon--photo-spot.svg);
      width: 30px;
      height: 30px;

      &.white {
        background-image: url(../../assets/images/icon--photo-spot-white.svg);
      }
    }

    &.icon--attra {
      background-image: url(../../assets/images/icon--attraction.svg);
      width: 30px;
      height: 30px;

      &.white {
        background-image: url(../../assets/images/icon--attraction-white.svg);
      }
    }

    &.icon--attra {
      background-image: url(../../assets/images/icon--attra.svg);
      width: 30px;
      height: 30px;

      &.white {
        background-image: url(../../assets/images/icon--attra-white.svg);
      }
    }

    &.icon--resta {
      background-image: url(../../assets/images/icon--resta.svg);
      width: 30px;
      height: 30px;

      &.white {
        background-image: url(../../assets/images/icon--resta-white.svg);
      }
    }

    &.icon--club {
      background-image: url(../../assets/images/icon--club.svg);
      width: 30px;
      height: 30px;

      &.white {
        background-image: url(../../assets/images/icon--club-white.svg);
      }
    }

    &.icon--spot-audio {
      background-image: url(../../assets/images/icon--spot-audio.svg);
      width: 24px;
      height: 24px;
    }

    &.icon--spot-photo {
      background-image: url(../../assets/images/icon--spot-photo.svg);
      width: 24px;
      height: 24px;
    }

    &.icon--spot-attra {
      background-image: url(../../assets/images/icon--spot-attra.svg);
      width: 24px;
      height: 24px;
    }

    &.icon--spot-resta {
      background-image: url(../../assets/images/icon--spot-resta.svg);
      width: 24px;
      height: 24px;
    }

    &.icon--spot-club {
      background-image: url(../../assets/images/icon--spot-club.svg);
      width: 24px;
      height: 24px;
    }

    &.icon--back {
      background-image: url(../../assets/images/icon--back.svg);
      width: 28px;
      height: 28px;

      &.white {
        background-image: url(../../assets/images/icon--back-white.svg);
      }
    }

    &.icon--dropdown-arrow {
      background-image: url(../../assets/images/icon--dropdown-arrow.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--menu-arrow {
      background-image: url(../../assets/images/icon--menu-arrow.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--location {
      background-image: url(../../assets/images/icon--location.svg);
      width: 28px;
      height: 28px;

      &.active {
        background-image: url(../../assets/images/icon--location-active.svg);
      }
    }

    &.icon--location-pin {
      background-image: url(../../assets/images/icon--location-pin.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--save {
      background-image: url(../../assets/images/icon--save.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--post-like {
      background-image: url(../../assets/images/icon--post-like.svg);
      width: 18px;
      height: 18px;

      &.white {
        background-image: url(../../assets/images/icon--post-like-white.svg);
      }

      &.active {
        background-image: url(../../assets/images/icon--post-like-active.svg);
      }
    }

    &.icon--post-comment {
      background-image: url(../../assets/images/icon--post-comment.svg);
      width: 18px;
      height: 18px;

      &.white {
        background-image: url(../../assets/images/icon--post-comment-white.svg);
      }

      &.active {
        background-image: url(../../assets/images/icon--post-comment-active.svg);
      }
    }

    &.icon--post-ring {
      background-image: url(../../assets/images/icon--post-ring.svg);
      width: 18px;
      height: 18px;

      &.active {
        background-image: url(../../assets/images/icon--post-ring-active.svg);
      }
    }

    &.icon--post-share {
      background-image: url(../../assets/images/icon--post-share.svg);
      width: 18px;
      height: 18px;

      &.white {
        background-image: url(../../assets/images/icon--post-share-white.svg);
      }
    }

    &.icon--translation {
      background-image: url(../../assets/images/icon--translation.svg);
      width: 20px;
      height: 20px;
    }

    &.icon--translate {
      background-image: url(../../assets/images/icon--translate.svg);
      width: 20px;
      height: 20px;

      &.active {
        background-image: url(../../assets/images/icon--translate-active.svg);
      }
    }

    &.icon--reply {
      background-image: url(../../assets/images/icon--reply.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--plus {
      background-image: url(../../assets/images/icon--plus.svg);
      width: 22px;
      height: 22px;
    }

    &.icon--gallery {
      background-image: url(../../assets/images/icon--gallery.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--refresh {
      background-image: url(../../assets/images/icon--refresh.svg);
      width: 24px;
      height: 24px;
    }

    &.icon--more {
      background-image: url(../../assets/images/icon--more.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--copy {
      background-image: url(../../assets/images/icon--copy.svg);
      width: 24px;
      height: 24px;
    }

    &.icon--time {
      background-image: url(../../assets/images/icon--time.svg);
      width: 32px;
      height: 32px;

      &.red {
        background-image: url(../../assets/images/icon--time-red.svg);
      }
    }

    &.icon--star-point {
      background-image: url(../../assets/images/icon--star-point.svg);
      width: 32px;
      height: 32px;
    }

    &.icon--share {
      background-image: url(../../assets/images/icon--share.svg);
      width: 32px;
      height: 32px;
    }

    &.icon--place {
      background-image: url(../../assets/images/icon--place.svg);
      width: 32px;
      height: 32px;
    }

    &.icon--pause {
      background-image: url(../../assets/images/icon--pause.svg);
      width: 18px;
      height: 20px;
    }

    &.icon--play {
      background-image: url(../../assets/images/icon--play.svg);
      width: 20px;
      height: 24px;
    }

    &.icon--play-prev {
      background-image: url(../../assets/images/icon--play-prev.svg);
      width: 39px;
      height: 17px;
    }

    &.icon--play-next {
      background-image: url(../../assets/images/icon--play-next.svg);
      width: 39px;
      height: 17px;
    }

    &.icon--arrow-small-top {
      background-image: url(../../assets/images/icon--arrow-small-top.svg);
      width: 15px;
      height: 8px;
    }

    &.icon--arrow-small-bottom {
      background-image: url(../../assets/images/icon--arrow-small-bottom.svg);
      width: 15px;
      height: 8px;
    }

    &.icon--hamburger {
      background-image: url(../../assets/images/icon--hamburger.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--hamburger-line {
      background-image: url(../../assets/images/icon--hamburger-line.svg);
      width: 28px;
      height: 28px;
    }

    &.icon--chat-re {
      background-image: url(../../assets/images/icon--chat-re.svg);
      width: 12px;
      height: 12px;
    }

    &.icon--chat-delete {
      background-image: url(../../assets/images/icon--chat-delete.svg);
      width: 12px;
      height: 12px;
    }

    &.icon--arrow-right {
      background-image: url(../../assets/images/icon--arrow-right.svg);
      width: 32px;
      height: 32px;
    }

    &.icon--arrow-left {
      background-image: url(../../assets/images/icon--arrow-left.svg);
      width: 32px;
      height: 32px;
    }

    &.icon--tabbar-home {
      background-image: url(../../assets/images/icon--tabbar-home.svg);
      width: 28px;
      height: 28px;

      &.active {
        background-image: url(../../assets/images/icon--tabbar-home-active.svg);
      }
    }

    &.icon--tabbar-community {
      background-image: url(../../assets/images/icon--tabbar-community.svg);
      width: 28px;
      height: 28px;

      &.active {
        background-image: url(../../assets/images/icon--tabbar-community-active.svg);
      }
    }

    &.icon--tabbar-place {
      background-image: url(../../assets/images/icon--tabbar-place.svg);
      width: 28px;
      height: 28px;

      &.active {
        background-image: url(../../assets/images/icon--tabbar-place-active.svg);
      }
    }

    &.icon--tabbar-chat {
      background-image: url(../../assets/images/icon--tabbar-chat.svg);
      width: 28px;
      height: 28px;

      &.active {
        background-image: url(../../assets/images/icon--tabbar-chat-active.svg);
      }
    }

    &.icon--tabbar-notification {
      background-image: url(../../assets/images/icon--tabbar-notification.svg);
      width: 28px;
      height: 28px;

      &.active {
        background-image: url(../../assets/images/icon--tabbar-notification-active.svg);
      }
    }

    &.icon--tabbar-mypage {
      background-image: url(../../assets/images/icon--tabbar-mypage.svg);
      width: 28px;
      height: 28px;

      &.active {
        background-image: url(../../assets/images/icon--tabbar-mypage-active.svg);
      }
    }
  }
}
