.default-layout {
  height: 100vh;
  overflow: auto;

  .layout-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    padding-top: calc(env(safe-area-inset-top));
    background-color: #FFFFFF;
    border-bottom: 1px solid #DCDCE0;
    z-index: 99999;

    &.slide-enter {
      opacity: 0;
      transform: translateY(-100%);
    }

    &.slide-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.5s ease,
                transform 0.5s ease;
    }

    &.slide-exit {
      opacity: 1;
      transform: translateY(0);
    }

    &.slide-exit-active {
      opacity: 0;
      transform: translateY(-100%);
      transition: opacity 0.5s ease,
                transform 0.5s ease;
    }

    .layout-container {
      height: 100%;
    }
  }

  .layout-content {
    position: relative;
    min-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    box-sizing: border-box;
    transition: padding 0.5s ease;
    margin-top: calc(env(safe-area-inset-top));
    margin-bottom: calc(env(safe-area-inset-bottom));

    .layout-content-container {
      min-height: 100%;
      padding-top: calc(env(safe-area-inse-top));

      &.slide-enter {
        opacity: 0;

        &.forward {
          transform: translateX(50%);
        }

        &.backward {
          transform: translateX(-150%);
        }
      }

      &.slide-enter-active {
        position: absolute;
        width: 100%;
        left: 50%;
        opacity: 1;
        transition: opacity 0.5s ease,
                    transform 0.5s ease;

        &.forward,
        &.backward {
          transform: translateX(-50%);
        }

        .data-loader {
          top: calc(50vh - 58px);
        }
      }

      &.slide-exit {
        opacity: 1;

        &.forward,
        &.backward {
          transform: translateX(-50%);
        }
      }

      &.slide-exit-active {
        position: absolute;
        width: 100%;
        left: 50%;
        opacity: 0;
        transition: opacity 0.5s ease,
                    transform 0.5s ease;

        &.forward {
          transform: translateX(-150%);
        }

        &.backward {
          transform: translateX(50%);
        }

        .data-loader {
          top: calc(50vh - 58px);
        }
      }
    }

    .page-slide-enter {
      opacity: 0;
      left: 100vw;

      &.backward {
        left: -100vw;
      }
    }

    .page-slide-enter-active {
      position: fixed;
      left: 0;
      width: 100vw;
      opacity: 1;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
      transition: opacity 0.5s ease,
                  left 0.5s ease;

      &.backward {
        left: 0;
      }
    }

    .page-slide-exit {
      opacity: 1;
      left: 0;

      &.backward {
        left: 0;
      }
    }

    .page-slide-exit-active {
      position: fixed;
      left: -100vw;
      width: 100vw;
      opacity: 0;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
      transition: opacity 0.5s ease,
                  left 0.5s ease;

      &.backward {
        left: 100vw;
      }
    }

    .ptr--ptr {
      box-shadow: none;
    }
  }

  .layout-sign-in {
    padding-bottom: 5px;

    .layout-sign-in-p {
      font-size: 14px;
      text-align: center;
      padding: 21px 15px 7px 15px;
    }
  }

  .layout-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    z-index: 99999;
    padding-bottom: calc(env(safe-area-inset-bottom));

    &.slide-enter {
      opacity: 0;
      transform: translateY(100%);
    }

    &.slide-enter-active {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.5s ease,
                  transform 0.5s ease;
    }

    &.slide-exit {
      opacity: 1;
      transform: translateY(0);
    }

    &.slide-exit-active {
      opacity: 0;
      transform: translateY(100%);
      transition: opacity 0.5s ease,
                  transform 0.5s ease;
    }

    .layout-container {
      height: 100%;
    }
  }

  .layout-container {
    position: relative;
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  .pop-bottom-enter {
    opacity: 0;
    bottom: -30px;
  }

  .pop-bottom-enter-active {
    opacity: 1;
    bottom: 0;
    transition: opacity 0.5s ease, bottom 0.5s ease;
  }

  .pop-bottom-exit {
    opacity: 1;
    bottom: 0;
  }

  .pop-bottom-exit-active {
    opacity: 0;
    bottom: -30px;
    transition: opacity 0.5s ease, bottom 0.5s ease;
  }

  .pop-top-enter {
    opacity: 0;
    transform: translateY(-10px);
  }

  .pop-top-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .pop-top-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .pop-top-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
}