.ui-input {
  position: relative;
  display: block;
  border: none;
  font-size: 16px;
  line-height: 23px;
  width: calc(100% - 48px);
  padding: 10px 0 29px;
  margin: 4px 24px;

  input,
  textarea {
    appearance: none;
    width: 100%;
    margin: -9px 0;
    padding: 6px 0 12px;
    font-size: 14px;
    line-height: 20px;
    border: none;
    border-bottom: 1px solid #ECECEF;
    border-radius: 0;
    background: transparent;
    font-family: inherit;
    color: #191919;
    resize: none;

    &::placeholder {
      color: #999999;
      letter-spacing: normal;
    }

    &[type=password] {
      letter-spacing: 0.4em;
    }

    &:focus {
      outline: 0;
    }
  }

  .datepicker {
    position: relative;
    background: transparent;
    margin: -10px 0;

    .datepicker-content {
      padding: 0;

      .datepicker-col-1 {
        margin: 0;

        .datepicker-wheel {
          border-color: #DCDCE0;
        }
      }
    }

    .datepicker-scroll > li {
      font-size: 18px;
    }

    .datepicker-viewport::after {
      background: -webkit-gradient(linear,left top, left bottom,from(#FFFFFF),color-stop(52%, rgba(255, 255, 255, 0)),color-stop(48%, rgba(255, 255, 255, 0)),to(#FFFFFF));
      background: linear-gradient(#FFFFFF,rgba(255, 255, 255, 0)52%,rgba(255, 255, 255, 0)48%,#FFFFFF);
    }
  }

  .input-wrap {
    position: relative;
  }

  .input-icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 22px;
    height: 22px;
    transform: translateY(-50%);

    .ui-icon {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .input-eye {
    position: absolute;
    top: 50%;
    right: 0;
    width: 22px;
    height: 22px;
    transform: translateY(-50%);

    .ui-icon {
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;

      .icon--eye-slash {
        transition: width 0.15s ease,
                    height 0.15s ease;

        &.hide {
          width: 0;
          height: 0;
        }
      }
    }
  }

  .input-eraser {
    position: absolute;
    top: 50%;
    right: 0;
    width: 22px;
    height: 22px;
    transform: translateY(-50%);

    .ui-icon {
      position: absolute;
      top: 0;
      right: 0;

      .icon--close-circle {
        opacity: 1;
        transition: opacity 0.1s ease;

        &.hide {
          opacity: 0;
        }
      }
    }
  }

  .input-label {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    line-height: 22px;
    color: #191919;
    font-weight: bold;
    margin-bottom: 15px;
    display: block;
  }

  .input-description,
  .input-error {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    line-height: 18px;
  }

  .input-description {
    color: #3154FF;
  }

  .input-error {
    color: #FF0000;
  }

  .input-max {
    position: absolute;
    bottom: 5px;
    right: 2px;
    width: 50px;
    text-align: right;
    font-size: 12px;
    line-height: 1.5;
    color: #AAAAAA;
  }

  &.has-icon {
    input,
    textarea {
      width: calc(100% - 14px);
      padding-left: 14px;
    }
  }

  &.show-icon {
    input,
    textarea {
      width: calc(100% - 36px);
      padding-right: 36px;
    }
  }

  &.has-error {
    .input-description {
      display: none;
    }
  }

  &.has-max {
    .input-description,
    .input-error {
      width: calc(100% - 50px);
    }
  }

  &.style-fill {
    padding-bottom: 25px;

    .input-label {
      margin-bottom: 11px;
    }

    .input-wrap {
      background-color: #ECECEF;
      padding: 10px 12px;
      border-radius: 8px;
    }

    input,
    textarea {
      margin: -10px -12px;
      padding: 7px 12px 13px;
      border-bottom: none;

      &:disabled {
        opacity: 0.7;
      }
    }

    .input-icon {
      left: 12px;
    }

    .input-eye {
      right: 12px;
    }

    .input-eraser {
      right: 12px;
    }

    &.has-icon {
      input,
      textarea {
        width: calc(100% - 40px);
        padding-left: 40px;
      }
    }

    &.show-icon {
      input,
      textarea {
        width: calc(100% - 28px);
        padding-right: 42px;
      }
    }
  }

  &.style-box {
    padding-bottom: 25px;

    .input-label {
      margin-bottom: 15px;
    }

    .input-wrap {
      padding: 9px 12px 10px;
    }

    input,
    textarea {
      width: calc(100% - 2px);
      margin: -15px -12px;
      padding: 15px 12px;
      border: 1px solid #DCDCE0;
      border-radius: 8px;
      transition: border-color 0.15s ease;

      &:focus {
        border-color: #3154FF;
      }

      &:disabled {
        background: #ECECEF;
      }
    }

    .input-icon {
      left: 12px;
    }

    .input-eye {
      right: 12px;
    }

    .input-eraser {
      right: 12px;
    }

    &.has-icon {
      input,
      textarea {
        width: calc(100% - 43px);
        padding-left: 43px;
      }
    }

    .input-error,
    .input-description {
      position: static;
      white-space: normal;
      margin: 10px 0;
    }

    .input-description {
      color: #999999;
      font-size: 14px;
      line-height: 20px;

      & + .input-error {
        margin-top: -10px;
      }
    }

    &.has-error {
      input,
      textarea {
        border-color: #FF0000;
      }

      .input-description {
        display: block;
      }
    }
  }

  &.style-float {
    width: calc(100% - 40px);
    padding: 10px 0 20px;
    margin: 4px 24px;

    .input-label {
      margin-bottom: 15px;
    }

    .input-wrap {
      padding: 9px 12px 10px;
    }

    input,
    textarea {
      width: calc(100% - 2px);
      margin: -13px -12px;
      padding: 13px 12px;
      background: #FFFFFF;
      box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      transition: border-color 0.15s ease;

      &:disabled {
        background: #ECECEF;
      }
    }

    .input-icon {
      left: 12px;
    }

    .input-eye {
      right: 12px;
    }

    .input-eraser {
      right: 12px;
    }

    &.has-icon {
      input,
      textarea {
        width: calc(100% - 43px);
        padding-left: 43px;
      }
    }

    .input-error,
    .input-description {
      position: static;
      white-space: normal;
      margin: 10px 0;
    }

    .input-description {
      color: #999999;
      font-size: 14px;
      line-height: 20px;

      & + .input-error {
        margin-top: -10px;
      }
    }

    &.has-error {
      .input-description {
        display: block;
      }
    }
  }
}