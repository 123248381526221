.community-board-request {
  .request-header {
    position: relative;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .request-back {
    height: 28px;
    vertical-align: middle;

    .ui-icon {
      margin-left: 16px;
    }
  }

  .request-title {
    position: relative;
    display: inline-block;
    height: 28px;
    margin: 0 8px;
    flex: 1 1 auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    h1 {
      position: absolute;
      font-size: 18px;
      line-height: 26px;
      font-weight: bold;
    }
  }

  .request-body {
    border-top: 1px solid #DCDCE0;

    .request-content {
      margin: calc((100vh - min(100vh, 455px)) * 0.5 + 15px) 0;
      padding-bottom: 85px;

      .description {
        border-radius: 8px;
        border: solid 1px #ECECEF;
        padding: 20px 14px;
        margin: 0 24px;

        h5 {
          font-size: 14px;
          color: #999999;
          line-height: 1.43;
          font-weight: bold;
          margin-bottom: 8px;
        }

        li {
          position: relative;
          font-size: 14px;
          color: #999999;
          line-height: 1.43;
          margin: 2px 0;
        }

        ol {
          list-style: decimal;
          margin-left: 1.7ch;
        }

        ul {
          margin-left: 1.1ch;

          li::before {
            content: "";
            display: block;
            width: 3px;
            height: 3px;
            border-radius: 100%;
            background: #999999;
            position: absolute;
            top: 0.65em;
            left: -1.3ch;
          }
        }
      }
    }
  }

  .request-footer {

  }
}