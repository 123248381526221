.ui-select {
  position: relative;
  display: block;
  border: none;
  font-size: 16px;
  line-height: 23px;
  width: calc(100% - 48px);
  margin: 14px 24px 33px;

  input {
    appearance: none;
    width: calc(100% - 28px);
    margin: -9px 0;
    padding: 4px 28px 10px 0;
    font-size: 16px;
    line-height: 24px;
    border: none;
    border-bottom: 1px solid #ECECEF;
    border-radius: 0;
    background: transparent;
    font-family: inherit;
    color: #191919;
    cursor: pointer;

    &::placeholder {
      color: #999999;
      letter-spacing: normal;
    }

    &:focus {
      outline: 0;
    }
  }

  .select-wrap {
    position: relative;
    cursor: pointer;

    .icon--dropdown-arrow {
      position: absolute;
      top: calc(50% - 1px);
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;
      transition: transform 0.15s ease;
    }

    &.has-icon {
      input {
        padding-left: 44px;
        width: calc(100% - 28px - 44px);
      }

      .selected-icon {
        cursor: pointer;

        .ui-icon {
          position: absolute;
          top: calc(50% - 1px);
          left: 0;
          transform: translateY(-50%);
          border-radius: 6px;
          background-color: #F6F6FA;
          padding: 0 6px;
          margin-right: 12px;
          cursor: pointer;

          .icon--flag {
            border-radius: 2px;
          }
        }
      }
    }

    &.active {
      .icon--dropdown-arrow {
        transform: translateY(-50%) scaleY(-1);
      }
    }
  }

  .select-label {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    line-height: 22px;
    color: #191919;
    font-weight: bold;
    margin-bottom: 15px;
    display: block;
  }
}

.select-wrap {
  .select-dim {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: opacity 0.15s ease;
  }

  .select-layer {
    position: fixed;
    z-index: 1000000;
    bottom: 0;
    left: 50%;
    border-radius: 10px 10px 0 0;
    transform: translateX(-50%);
    background: #FFFFFF;
    width: 100%;
    max-width: 720px;
    padding-bottom: calc(env(safe-area-inset-bottom));
    transition: transform 0.2s ease;

    .select-search {
      padding-top: 6px;

      .ui-input {
        margin: 12px 24px;
        padding: 0;
      }
    }

    .select-items {
      overflow-y: auto;

      .select-item {
        .select-item-icon {
          border-radius: 6px;
          background-color: #F6F6FA;
          padding: 5px 6px;
          margin-right: 12px;

          .ui-icon {
            .icon--flag {
              border-radius: 2px;
            }
          }
        }

        button {
          display: flex;
          appearance: none;
          border: none;
          background-color: #FFFFFF;
          cursor: pointer;
          width: 100%;
          text-align: left;
          padding: 13px 24px;
          height: 52px;
          font-size: 16px;
          transition: background-color 0.15s ease;

          &.has-desc {
            font-size: 14px;
            padding: 5px 24px;
            line-height: 20px;
          }
        }

        .select-item-name-desc {
          display: block;
          flex: 1 1 auto;
          min-width: 1px;
        }

        .select-item-name {
          width: 100%;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .select-item-desc {
          width: 100%;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          color: #999999;
          line-height: 18px;
        }

        &.first-child {
          button {
            border-radius: 10px 10px 0 0;
          }
        }

        &:hover,
        &.selected {
          button {
            background-color: #ECECEF;
          }
        }
      }
    }
  }

  &.select-transition {
    .select-dim {
      opacity: 0;
    }

    .select-layer {
      transform: translate(-50%, 100%);
    }
  }
}
