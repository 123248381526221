.sign-up {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 56px - env(safe-area-inset-top));
  padding-top: calc(env(safe-area-inset-top));
  overflow: auto;
  background: #FFFFFF;

  .step-2 {
    padding: 19.3vh 0;
  }

  .step-3 {
    padding: 19.3vh 0;
  }

  .step-4 {
    padding: 3.2vh 0;

    .description {
      margin: 0 25px;
      min-height: 16.1vh;
      line-height: 1.38;
      font-size: 16px;
    }

    .check-item {
      .check-text {
        padding-left: 0;
        padding-right: 22px;
      }

      .ui-check {
        right: 0;
      }

      &::after {
        margin: 0;
      }
    }
  }

  .step-5 {
    padding: 3.2vh 0;

    .description {
      margin: 0 25px;
      min-height: 21.8vh;
      line-height: 1.38;
      font-size: 16px;
    }
  }

  .step-6 {
    padding: 3.2vh 0;

    .description {
      margin: 0 25px;
      min-height: 21.4vh;
      line-height: 1.38;
      font-size: 16px;
    }

    .ui-select {
      margin-bottom: 46px;
    }
  }

  .step-7 {
    height: calc(100vh - 57px - 101px);

    .profile-image {
      position: absolute;
      top: 27.8vh;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      text-align: center;
    }

    .description {
      font-size: 14px;
      line-height: 1.43;
      color: #000000;
      margin-top: 22px;
    }

    .ui-image {
      margin-right: 3px;
      margin-bottom: 12px;

      .profile-icon {
        position: absolute;
        bottom: -12px;
        right: -23px;
        border-radius: 50%;
        padding: 8px;
        box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
      }
    }
  }

  .step-8 {
    padding-bottom: 103px;

    .description {
      margin: 27px 24px;
      font-size: 18px;
      line-height: 1.33;
      font-weight: bold;
      text-align: center;
    }

    .interest-select {
      .interest-depth-1-item {
        margin: 12px 24px;
        border: 1px solid #DCDCE0;
        border-radius: 8px;

        p {
          font-size: 16px;
          line-height: 1.38;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 24px;
          padding: 13px;
          font-weight: bold;
          color: #999999;
          transition: color 0.1s ease;
          cursor: pointer;

          .ui-icon {
            margin-right: 6px;
          }
        }

        &.selected {
          p {
            color: #3154FF;
          }
        }

        .interest-depth-2 {
          height: 0;
          margin: 0 12px;
          border-top: 0 solid #DCDCE0;
          overflow: hidden;
          transition: height 0.15s ease,
                      border-top-width 0.15s ease,
                      padding 0.15s ease;

          .interest-depth-2-item {
            display: inline-block;
            text-align: center;
            min-width: 25px;
            margin: 10px 9px 0 0;
            font-size: 16px;
            line-height: 1.38;
            color: #767676;
            padding: 6px 13px;
            background-color: #ECECEF;
            border-radius: 18px;
            appearance: none;
            border: none;
            cursor: pointer;
            transition: color 0.1s ease,
                        background-color 0.1s ease;

            &.selected {
              color: #FFFFFF;
              background-color: #3154FF;
            }
          }
        }

        &.active {
          .interest-depth-2 {
            height: auto;
            padding: 3px 0 14px;
            border-top-width: 1px;
          }
        }
      }
    }
  }

  .sign-up-success {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 0.15s ease;

    &.show {
      opacity: 1;
    }

    .success-wrap {
      position: absolute;
      top: calc(50% + 22px);
      left: 50%;
      transform: translate(-50%, -50%);
      border: 3px solid #3154FF;
      border-radius: 10px;
      width: 85.5%;
      height: 60.5%;
      max-width: 616px;
      background: #FFFFFF;
    }

    .success-title {
      position: absolute;
      top: -49px;
      width: calc(100% - 26px);
      padding-bottom: 22px;
      margin: 0 13px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        z-index: 1;
        display: block;
        width: 75px;
        height: 73px;
        bottom: 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &::before {
        left: 0;
        background-image: url('../../assets/images/asset--ribbon-left.svg');
      }

      &::after {
        right: 0;
        background-image: url('../../assets/images/asset--ribbon-right.svg');
      }

      .success-title-wrap {
        position: relative;
        z-index: 2;
        display: block;
        color: #FFFFFF;
        background: #3154FF;
        height: 51px;
        line-height: 51px;
        overflow: hidden;
        font-size: 23px;
        font-weight: bold;
        text-align: center;
        margin: 0 35px;
        white-space: nowrap;
      }
    }

    .success-description {
      position: absolute;
      top: 50%;
      width: calc(100% - 50px);
      transform: translateY(-50%);
      font-size: 18px;
      text-align: center;
      line-height: 1.36;
      padding: 0 25px 50px;
    }

    .success-buttons {
      position: absolute;
      width: 100%;
      bottom: 6.5%;
      text-align: center;

      .load {
        padding: 10px 25px;
        color: #191919;
        font-size: 14px;
        transition: opacity 0.15s ease;

        .ui-icon {
          margin-right: 7px;
        }
      }

      .button {
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 90%;
        opacity: 0;
        transition: opacity 0.15s ease;
        pointer-events: none;
      }

      &.success {
        .load {
          opacity: 0;
        }

        .button {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}