.map-place {
  position: relative;
  height: calc(100% - 100px - env(safe-area-inset-top));
  padding-bottom: 100px;
  overflow: auto;
  scroll-behavior: smooth;
  padding-top: env(safe-area-inset-top);

  .data-loader {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }

  .thumbnail {
    margin-top: calc(-1 * env(safe-area-inset-top));
    height: 100vw;
    max-height: 600px;
    background-size: cover;
    background-position: center center;
  }

  .place-back {
    position: fixed;
    top: env(safe-area-inset-top);
    left: 0;
    width: 100%;
    height: 28px;
    vertical-align: middle;
    padding: 14px 0;
    z-index: 100;

    .ui-icon {
      margin-left: 16px;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 20px;
      padding: 3px;
    }
  }

  .place-container {
    background-color: #F6F6FA;
    margin-bottom: 16px;
  }

  .info {
    color: #191919;
    padding: 27px 32px;

    .title {
      h3 {
        text-align: center;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
      }
    }

    .meta {
      text-align: center;

      .item {
        display: inline-block;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
        margin: 20px 0;
        width: 25%;

        span {
          font-weight: bold;
          color: #191919;
        }

        .ui-icon {
          i.icon--bookmark {
            margin: 3px 2px 1px;
          }
        }
      }
    }

    .description {
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      color: #888888;
    }
  }

  .information {
    color: #191919;

    .info-title {
      padding: 32px 20px 20px;
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
    }

    .info-content {
      padding: 0 30px 32px;
      font-size: 14px;
      line-height: 20px;

      &.gallery {
        width: calc(100% - 20px);
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        padding: 0 20px 0 0;

        .gallery-item {
          display: inline-block;
          width: 250px;
          height: 250px;
          border-radius: 16px;
          margin: 0 0 20px 20px;
          background-size: cover;
          background-position: center center;
          cursor: pointer;
        }
      }

      .wysiwyg {
        margin: -10px 0;
      }

      .open-time {
        margin: -8px 0;

        li {
          margin: 8px 0;

          &::after {
            content: "";
            display: block;
            clear: both;
          }

          h6 {
            float: left;
            font-size: 16px;
            line-height: 22px;
          }

          p {
            float: right;
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            margin: 0;

            span {
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #3154FF;
            }
          }
        }
      }
    }

    .info-button {
      position: absolute;
      top: 28px;
      right: 0;
      cursor: pointer;
      margin: 5px 20px;
      padding: 0;
      border: none;
      background-color: transparent;
      font-size: 14px;
      line-height: 20px;
      color: #888888;

      .ui-icon {
        margin-right: 5px;
      }
    }

    .audio-bar {
      padding: 37px 30px 0;
      margin: 0 auto;

      .bar {
        position: relative;
        width: 100%;
        height: 6px;
        background: #DCDCE0;
        border-radius: 3px;

        &.animating {
          .past {
            transition: width 0.3s ease;
          }

          .current {
            transition: left 0.3s ease;
          }
        }

        .past {
          height: 100%;
          background: #DCDCE0;
          border-radius: 3px;
          background: rgba(49, 84, 255, 0.4);
        }

        .current {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 16px;
          height: 16px;
          background: #3154FF;
          border-radius: 8px;
        }
      }

      .audio-time {
        font-size: 12px;
        line-height: 18px;
        margin: 4px -1px;

        &::after {
          content: "";
          display: block;
          clear: both;
        }

        .current {
          float: left;
          color: #3154FF;
        }

        .total {
          float: right;
          color: #767676;
        }
      }
    }

    .audio-control {
      text-align: center;
      padding-bottom: 32px;

      button {
        width: 64px;
        height: 64px;
        border-radius: 32px;
        border: none;
        text-align: center;
        vertical-align: middle;
        background-color: transparent;
        margin: 0 25px;
        cursor: pointer;

        .ui-icon {
          i {
            vertical-align: middle;

            &.icon--play {
              margin-left: 6px;
            }
          }
        }

        &.skip {
          font-size: 10px;
          line-height: 16px;
          color: #767676;
        }

        &.play {
          background-color: #DCDCE0;
        }
      }
    }

    .audio-script {
      background-color: #FFFFFF;
      color: #191919;

      &.show {
        .script-content {
          max-height: 0;
        }
      }

      .script-title {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        background: #DCDCE0;
        text-align: center;
        padding: 10px;
        cursor: pointer;

        .ui-icon {
          i {
            vertical-align: middle;
            margin: -6px 0 0 5px;
          }
        }
      }

      .script-content {
        max-height: 224px;
        overflow-y: auto;
        transition: max-height 0.5s cubic-bezier(0.65, 0, 0.35, 1);

        .script-content-wrap {
          padding: 20px 32px;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}