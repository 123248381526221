.ui-enlarge-image {
  z-index: 100200;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #191919;

  .image-close {
    position: absolute;
    z-index: 100201;
    top: calc(env(safe-area-inset-top) + 16px);
    right: 16px;
  }

  .image-images {
    transition: transform 0.5s ease;
    white-space: nowrap;

    .react-transform-wrapper {
      display: inline-block;
    }
  }

  .image-image {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100vw;
      max-height: calc(100vh - 108px);
    }
  }

  .meta-container {
    position: absolute;
    z-index: 100201;
    bottom: calc(env(safe-area-inset-bottom));
    left: 50%;
    width: 100%;
    height: 54px;
    transform: translateX(-50%);
  }
}