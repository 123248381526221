.signin {
  position: relative;
  max-width: 720px;
  height: 100vh;
  margin: 0 auto;

  .signin-wrap {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);

    @media (max-height: 661px) {
      position: static;
      top: auto;
      transform: translateY(0);
    }
  }

  @media (max-height: 444px) {
    height: auto;
    padding-bottom: 1px;
  }

  .signin-header {
    position: absolute;
    top: 14px;
    left: 16px;
    padding-top: env(safe-area-inset-top);
  }

  .signin-logo {
    text-align: center;
    margin: 0 25px 24% 25px;
    padding-top: 25px;

    @media (max-height: 661px) {
      margin-bottom: calc(24% - 661px + 462.7px + 30vh);
    }

    @media (max-height: 444px) {
      margin-bottom: 40.5px;
    }
  }

  .signin-buttons {
    margin-top: -3px;
    margin-bottom: 230px;

    .signup {
      display: block;
      text-align: center;
      margin: 10px 25px;
      text-decoration: none;
      color: black;
    }

    @media (max-height: 444px) {
      margin-bottom: 0;
    }
  }

  .forgot-password {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: calc(100% - 50px);
    text-align: center;
    margin: 0 25px 34px 25px;
    font-size: 12px;
    line-height: 1.5;
    text-decoration: none;
    color: #999999;

    @media (max-height: 444px) {
      position: static;
      bottom: auto;
      left: auto;
      margin-top: 24px;
    }
  }
}
